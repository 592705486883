import React from "react";
import { Link } from "react-router-dom";
import headshot from "../images/headshot-new.png";

const AboutShort = () => {
  return (
    <>
      <div className="about-short-container">
        <div style={{ padding: "4rem" }}>
          <h1>About Dr. Grace L. Kim, MD</h1>

          <div className="headshot-image-container">
            <img
              src={headshot}
              alt="headshot"
              className="headshot-image"
              style={{ filter: "grayscale(80%)" }}
            />
          </div>

          <p className="about-text">
            Dr. Grace L. Kim is the former owner of Rejuvenation Medical
            Skincare and Laser, Inc., a full service medical spa in San Luis
            Obispo, which she operated from 2005-2014. During those nine years,
            she fully dedicated herself to the operations of the medical spa
            industry, reaching the Platinum level with Allergan (makers of Botox
            cosmetic). In 2018, she opened ShotDocMD in San Jose, in order to
            provide cutting-edge injectable services to the San Jose area. After
            five successful years in San Jose, ShotDocMD returned to San Luis
            Obispo. She is one of the most-trained and experienced injectors in
            the area and she continues to focus on the latest techniques and
            products for a superlative patient experience.
          </p>
          <Link to="/contact/about">
            <button className="button-more">Read More</button>
          </Link>
        </div>
      </div>

      <div className="about-short-container-mobile">
        <div
          style={{ padding: "4rem 0" }}
          className="about-short-mobile-column"
        >
          <h1>About Dr. Grace L. Kim, MD</h1>

          <div className="headshot-image-container">
            <img src={headshot} alt="headshot" className="headshot-image" />
          </div>

          <p className="about-text">
            Dr. Grace L. Kim is the former owner of Rejuvenation Medical
            Skincare and Laser, Inc., a full service medical spa in San Luis
            Obispo, which she operated from 2005-2014. During those nine years,
            she fully dedicated herself to the operations of the medical spa
            industry, reaching the Platinum level with Allergan (makers of Botox
            cosmetic). In 2018, she opened ShotDocMD in San Jose, in order to
            provide cutting-edge injectable services to the San Jose area. She
            is one of the most-trained and experienced injectors in the area and
            she continues to focus on the latest techniques and products for a
            superlative patient experience.
          </p>
          <Link to="/contact/about">
            <button className="button-more">Read More</button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default AboutShort;
