export const COLORS = {
    primary: "#F16681",
    primaryHover: "#e78497",
    primaryLight: "#4d4d4d",
    primaryAlt: "#313131",
    primaryDark: "#fff",
    primaryDarkHover: "#dadada",
    primaryDarkest: "#fff",
    primaryDarkestHover: "#dadada",
    secondary: "#f6a0a4",
    secondaryHover: "#f9babe",
    secondaryHoverAlt: "#303030",
    third: "rgb(205, 193, 193)",
    thirdHover: "rgb(180, 180, 177)",
    fourth: "#ffffff",
    fourthHover: "#b3b3b4",
}