import React, { useEffect } from "react";
import image from "../images/carousel2.jpg";
import FadeInSection from "../components/FadeInSection";
import ContactForm from "../components/ContactForm";
import MailListForm from "../components/MailListForm";
import Directions from "../components/Directions";
import FadeIn from "../components/FadeIn";

const MelasmaScreen = () => {
  useEffect(() => {
    document.title = "Melasma and Hyperpigmentation - ShotDocMD";
  }, []);

  return (
    <div className="contain">
      <div className="info-screen-container">
        <div className="info-screen-main">
          <FadeIn>
            <h1>Melasma and Hyperpigmentation</h1>

            <div className="subheading">
              Are you looking to treat melasma and hyperpigmentation near Los
              Gatos, CA?
            </div>
          </FadeIn>

          <br></br>
          <FadeInSection>
            {" "}
            <div style={{ margin: "1rem 0 3rem 0" }}>
              <img src={image} alt="botox" className="image" />
            </div>
          </FadeInSection>
          <FadeInSection>
            {" "}
            <div className="text-med">
              Pigmentation of the skin is a frustrating problem for many
              patients. Melasma, however, is a specific skin condition that
              affects approximately 6 million Americans per year, mostly women.
              These brown or grayish-brown blotches, typically on the forehead,
              chin, cheeks, upper lip, or nose, are caused by over-active
              pigment cells, or melanocytes. The condition is not
              life-threatening but does cause significant distress for those who
              suffer from it.
            </div>
          </FadeInSection>
          <br></br>

          <FadeInSection>
            <div className="text-med">
              There are several causes of melasma, but the most common include
              hormone changes and sun exposure. Fluctuations in hormones, such
              as pregnancy, can be the first sign of melasma. Melasma may also
              occur when you either start or stop hormonal contraception,
              including birth control pills, or when you take hormone
              replacement therapy.
            </div>
          </FadeInSection>
          <FadeInSection>
            <div className="text-med">
              The sun, however, is the biggest factor stimulating this vexing
              skin condition. We need sunshine but how can we get the
              much-needed Vitamin D and not aggravate melasma?
            </div>
          </FadeInSection>
          <br></br>
          <FadeInSection>
            <div className="text-med">
              Bottom line, no one wants to limit their lifestyle during the
              summer months when the days are long and we can spend lots of time
              outdoors. Using sun protection is the most important way we can
              protect our skin and that means reapplying your sunscreen every 2
              hours. Also, avoid the sun during the time of day that has the
              highest UV rays from 10am to 4pm. Wear a wide-brim hat when
              outdoors. These rules apply even when it is cloudy outside as the
              UVB sun rays can penetrate the skin and contribute to sunburns,
              premature aging and melasma.
            </div>
          </FadeInSection>
          <br></br>

          <FadeInSection>
            <div className="text-med">
              Treatments for hyperpigmentation include microneedling, chemical
              peels and intense pulsed light. Heat from lasers and/or
              photofacial devices can cause hyperpigmentation and exacerbate
              melasma so should be used with caution by an experienced provider.
            </div>
          </FadeInSection>
        </div>
        <div className="info-screen-side">
          <div style={{ marginBottom: "3rem" }}>
            {" "}
            <ContactForm small={true} />
          </div>
          <div style={{ marginBottom: "3rem" }}>
            <Directions />
          </div>
          <MailListForm small={true} />
        </div>
      </div>
    </div>
  );
};

export default MelasmaScreen;
