import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch, useSelector } from "react-redux";
import { sendEmailList } from "../actions/contactActions";
import Spinner from "react-bootstrap/Spinner";
import FadeIn from "./FadeIn";

const MailListForm = (props) => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [canSubmit, setCanSubmit] = useState(false);
  const [errorMessage, setError] = useState("");

  const emailListSend = useSelector((state) => state.emailListSend);
  const { success, loading, error } = emailListSend;

  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();
    if (!email || !firstName || !lastName) {
      setError("All fields must be filled.");
    } else {
      dispatch(
        sendEmailList({email, firstName, lastName})
      );
    }
  };

  function onChange(value) {
    setCanSubmit(true);
  }

  return (
    <div>
      <FadeIn>
        <div
          className={props.small ? "column contact-form-container" : "column"}
        >
          {props.small && <h2 className="center">Join our Newsletter</h2>}
          {success ? (
            <div className="success-message fade-in">
              Your have successfully joined our newsletter
            </div>
          ) : (
            <form onSubmit={(e) => submitHandler(e)}>
              <div className="label-input column">
                <label htmlFor="firstName">First Name</label>
                <input
                  id="firstName"
                  type="text"
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                    setError("");
                  }}
                  placeholder="Your First Name"
                ></input>
              </div>
              <div className="label-input column">
                <label htmlFor="lastName">Last Name</label>
                <input
                  id="lastName"
                  type="text"
                  value={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value);
                    setError("");
                  }}
                  placeholder="Your Last Name"
                ></input>
              </div>
              <div className="label-input column">
                <label htmlFor="email">Email Address</label>
                <input
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setError("");
                  }}
                  placeholder="Your Email"
                ></input>
              </div>
              <ReCAPTCHA
                sitekey="6LehJbEaAAAAABLrB9PGdnWSYNyy-vcbp_iEM_8Q"
                onChange={onChange}
                className="label-input center"
                size="normal"
              />
              <div className="error-message center">{errorMessage}</div>
              <div className="error-message center">{error}</div>
              <button
                type="submit"
                className="submit-button"
                disabled={!canSubmit ? true : false}
              >
                {loading ? (
                  <Spinner animation="border" variant="light" />
                ) : (
                  "Submit"
                )}
              </button>
            </form>
          )}
        </div>
      </FadeIn>
    </div>
  );
};

export default MailListForm;
