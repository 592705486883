import React, { useState } from "react";

import Rating from "./rating";

const Reviews = (props) => {
  const [visible, setVisible] = useState(0);
  return (
    <div
      className={
        props.background === true
          ? "reviews-container-background"
          : "reviews-container"
      }
    >
      <div
        className={
          props.background === true
            ? "row-full reviews-margin"
            : "row-full reviews-margin-large"
        }
      >
        <div className="column" style={{ minWidth: "100%" }}>
          <h2
            style={{ textAlign: "center" }}
            className={props.title === true ? "reviews-header" : "hidden"}
          >
            Reviews
          </h2>
          <div className="row-fixed align-center" style={{ width: "100%" }}>
            <div
              style={{
                width: "10%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              {" "}
              <button
                className="arrow-button"
                disabled={visible === 0}
                onClick={() => setVisible(visible - 1)}
              >
                {" "}
                <i className="fas fa-chevron-left"></i>
              </button>
            </div>
            <div
              style={{ width: "80%", overflow: "hidden" }}
              className="row-fixed"
            >
              <div className="reviews-card-container">
                <div className={props.shortened ? 'review-text-shortened' : "review-text"}>
                  {props.reviews[visible].review}
                </div>
                <div className="review-info-div">
                  <Rating rating={props.reviews[visible].stars} />
                  <p>
                    {props.reviews[visible].reviewer}{" "}
                    {props.reviews[visible].reviewer &&
                      props.reviews[visible].platform &&
                      "|"}{" "}
                    {props.reviews[visible].link ? (
                      <a
                        href={props.reviews[visible].link}
                        className="link-review"
                      >
                        {props.reviews[visible].platform}
                      </a>
                    ) : (
                      props.reviews[visible].platform
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div
              style={{
                width: "10%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              {" "}
              <button
                className="arrow-button"
                disabled={visible === props.reviews.length - 1}
                onClick={() => setVisible(visible + 1)}
              >
                {" "}
                <i className="fas fa-chevron-right"></i>
              </button>
            </div>
          </div>
          <div className="column">
            <h2 style={{ marginTop: "3rem" }} className="reviews-header center">
              Love ShotDocMD? Review Us!
            </h2>
            <div className="center">
              <div className="row-fixed center">
                <a href="https://www.yelp.com/writeareview/biz/9B7KcLpLG4me75L1Axx_jg?return_url=%2Fbiz%2F9B7KcLpLG4me75L1Axx_jg&source=biz_details_war_button">
                  {" "}
                  <i className="fab fa-yelp"></i>
                </a>
                <a href="https://www.google.com/search?q=shotdocmd&oq=shotdocmd&aqs=chrome..69i57j69i59l2j69i60l3.1348j0j4&sourceid=chrome&ie=UTF-8#lrd=0x808e355d1e22bdb1:0xeea0301d1b46ef11,3,,,">
                  <i className="fab fa-google"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reviews;
