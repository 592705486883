import React, { useState } from "react";
import googleMaps from "../assets/googleMaps.svg";
import appleMaps from "../assets/appleMaps.svg";
import { Flex } from "../utils/styles";
import styled from "styled-components";
import { COLORS } from "../utils/colors";

const Absolute = styled.span`
  position: absolute;
  left: -10px;
  top: 5px;
`;

const BackIcon = styled.i`
  font-size: 24px;
  color: ${COLORS.primary};
  transition: 0.2s;
  &:hover {
    color: ${COLORS.primaryHover};
  }
`;

const Directions = () => {
  const [location, setLocation] = useState(null);

  return (
    <div
      className="column"
      style={{ width: "100%", alignItems: "center", position: "relative" }}
    >
      {!!location && (
        <Absolute>
          <button onClick={() => setLocation(null)}>
            <BackIcon className="fa fa-chevron-left" aria-hidden="true" />
          </button>
        </Absolute>
      )}

      <h2 style={{ textAlign: "center" }}>Get Directions</h2>
      {location === "slo" ? (
        <>
          <h3>San Luis Obispo</h3>
          <div className="row-full">
            <a
              href="https://www.google.com/maps/place/1250+Peach+St,+San+Luis+Obispo,+CA+93401/@35.2864759,-120.6627888,17z/data=!3m1!4b1!4m6!3m5!1s0x80ecf100b52a358f:0x33eebcc46a04fd5e!8m2!3d35.2864715!4d-120.6602085!16s%2Fg%2F11bw3z068f?entry=ttu"
              // className="directions-button"
              className="maps-icons"
            >
              <img src={googleMaps} alt="Google Maps" width="90rem"></img>
            </a>
            <a
              href="http://maps.apple.com/?address=1250,Peach+St,San+Luis+Obispo,California"
              className="maps-icons"
            >
              <img
                src={appleMaps}
                alt="Apple Maps"
                width="82.5rem"
                style={{ marginTop: "1rem" }}
              />
            </a>
          </div>
        </>
      ) : location === "los-gatos" ? (
        <>
          <h3>Los Gatos</h3>
          <div className="row-full">
            <a
              href="https://www.google.com/maps/place/761+University+Ave,+Los+Gatos,+CA+95032/@37.2390858,-121.9756623,18.89z/data=!4m6!3m5!1s0x808e35948715bc5b:0x5df86cfb2d896bfb!8m2!3d37.2391854!4d-121.9752156!16s%2Fg%2F11gdqjffw6?entry=ttu"
              // className="directions-button"
              className="maps-icons"
            >
              <img src={googleMaps} alt="Google Maps" width="90rem"></img>
            </a>
            <a
              href="http://maps.apple.com/?address=761,University+Ave,Los+Gatos,California"
              className="maps-icons"
            >
              <img
                src={appleMaps}
                alt="Apple Maps"
                width="82.5rem"
                style={{ marginTop: "1rem" }}
              />
            </a>
          </div>{" "}
        </>
      ) : (
        <Flex flexDirection="column" gap="15px">
          <button className="button-green" onClick={() => setLocation("slo")}>
            SLO
          </button>
          <button
            className="button-green"
            onClick={() => setLocation("los-gatos")}
          >
            Los Gatos
          </button>
        </Flex>
      )}
    </div>
  );
};

export default Directions;
