import React, { useEffect } from "react";
import image from "../images/carousel4.jpg";
import FadeInSection from "../components/FadeInSection";
import ContactForm from "../components/ContactForm";
import MailListForm from "../components/MailListForm";
import Directions from "../components/Directions";
import FadeIn from "../components/FadeIn";

const MicroneedlingScreen = () => {
  useEffect(() => {
    document.title = "Microneedling - ShotDocMD";
  }, []);

  return (
    <div className="contain">
      <div className="info-screen-container">
        <div className="info-screen-main">
          <FadeIn>
            <h1>Microneedling</h1>

            <div className="subheading">
              Are you looking for microneedling in Los Gatos, California?
            </div>
          </FadeIn>

          <br></br>
          <FadeInSection>
            {" "}
            <div style={{ margin: "1rem 0 3rem 0" }}>
              <img src={image} alt="botox" className="image" />
            </div>
          </FadeInSection>
          <FadeInSection>
            {" "}
            <div className="text-med">
              Microneedling, also known as percutaneous collagen induction
              therapy, is a noninvasive skin care treatment used to improve the
              appearance of scars, boost collagen, and even encourage hair
              growth. Microneedling is the insertion of very fine short needles
              into the skin which creates a wound in the skin. The body responds
              to the needling by producing collagen. Microneedling can be used
              to treat acne and surgical scars—including keloids—as well as
              wrinkles, stretch marks, pore size, and signs of aging.
              Microneedling can offer the same results as a fractional laser
              without pain, downtime, expense, and thermal damage. For patients
              with hyperpigmentation or melasma, microneedling is an excellent
              alternative to laser treatments.
            </div>
          </FadeInSection>

          <FadeInSection>
            {" "}
            <h2 style={{ marginTop: "3rem", textAlign: "center" }}>FAQ's</h2>
          </FadeInSection>

          <FadeInSection>
            {" "}
            <h3 style={{ marginTop: "2rem" }}>
              How many microneedling treatments will I need?
            </h3>
            <div className="text-med">
              Typically a series of 3-6 microneedling treatments is recommended,
              depending upon your area of correction or concern. Your skincare
              professional will ultimately determine a treatment schedule based
              on your individual goals and desires. Treatments are usually
              spaced in 4 week intervals. Patients with deep wrinkles, advanced
              sun damage, stretch marks, or any type of scars, usually require 6
              or more treatments to achieve the desired results.
            </div>
          </FadeInSection>
          <FadeInSection>
            {" "}
            <h3 style={{ marginTop: "2rem" }}>
              What is the microneedling treatment like?
            </h3>
            <div className="text-med">
              A sterile disposable needle cartridge, holding either 12 or 36
              micro-needles is glided across the skin with the Collagen P.I.N.
              microneedling device that mechanically induces a micro injury to
              the desired depth chosen for your skin by your medical provider.
            </div>
          </FadeInSection>
          <FadeInSection>
            {" "}
            <h3 style={{ marginTop: "2rem" }}>
              Will microneedling procedure hurt?
            </h3>
            <div className="text-med">
              Prior to your treatment with the Collagen P.I.N. microneedling
              pen, the medical provider will apply a topical numbing agent. Most
              people say they feel almost nothing when a numbing cream is used.
            </div>
          </FadeInSection>
          <FadeInSection>
            {" "}
            <h3 style={{ marginTop: "2rem" }}>
              How long will it take for me to see results from my microneedling
              treatment?
            </h3>
            <div className="text-med">
              Most all patients see results after the first microneedling
              treatment. Individual results vary depending on individual skin
              health. It is most common to begin to see true changes or positive
              results as soon as 1-2 weeks after the first treatment. Many
              patients comment on positive changes in even less time. Often,
              change is noted within just a few days.
            </div>
          </FadeInSection>
          <FadeInSection>
            {" "}
            <h3 style={{ marginTop: "2rem" }}>
              How long will my microneedling results last?
            </h3>
            <div className="text-med">
              Microneedling produces results through the stimulation of growth
              factors and collagen, thereby promoting new skin cells to surface.
              As our skin ages, our collagen production naturally slows down.
              Your overall result will last longer when using an at home skin
              care regimen that includes a daily Retinoid product. Your medical
              provider is best suited to make this recommendation. New collagen
              and elastin formation in the skin are documented as visually and
              measurably improved as much as one year after just one treatment.
              However, a series of treatments is typically recommended for best
              results.
            </div>
          </FadeInSection>
          <FadeInSection>
            {" "}
            <h3 style={{ marginTop: "2rem" }}>
              What will I look like after my microneedling treatment?
            </h3>
            <div className="text-med">
              Microneedling is considered a no downtime treatment. Immediately
              after your treatment your skin will look slightly pink to
              moderately red. Your medical provider will recommend skin care
              products for your post care based on your individual skin, which
              will greatly minimize these symptoms and enhance your overall
              results.
            </div>
          </FadeInSection>
          <FadeInSection>
            {" "}
            <h3 style={{ marginTop: "2rem" }}>
              Can I use makeup after having my microneedling treatment?{" "}
            </h3>
            <div className="text-med">
              Directly after your treatment we recommend using only mineral
              based foundation, as it cannot build bacteria. You may go back to
              your regular foundation 4 days post treatment.
            </div>
          </FadeInSection>
        </div>
        <div className="info-screen-side">
          <div style={{ marginBottom: "3rem" }}>
            {" "}
            <ContactForm small={true} />
          </div>
          <div style={{ marginBottom: "3rem" }}>
            <Directions />
          </div>
          <MailListForm small={true} />
        </div>
      </div>
    </div>
  );
};

export default MicroneedlingScreen;
