import React, { useEffect } from 'react';
import FadeIn from '../components/FadeIn';
import Reviews from '../components/Reviews';

const reviews = [
  {
    review:
      "Love Dr. Kim. She's very knowledgeable and really takes time to explain the procedures and recommend the best options tailored to your specific needs. She makes you feel at ease throughout the procedures as well. 100% recommend!",
    reviewer: "",
    stars: 5,
    platform: "April 25, 2022",
    link: "",
  },
  {
    review:
      "Dr. Kim is gentle in the way she does her procedures very caring honest. She never disappoints.",
    reviewer: "",
    stars: 5,
    platform: "March 28, 2022",
    link: "",
  },
  {
    review:
      "Dr. Kim is an artist! She really knows what she is doing.  I now also love myself on the outside!!",
    reviewer: "",
    stars: 5,
    platform: "January 18, 2022",
    link: "",
  },
  {
    review:
      "Dr Kim has the expertise and eye of an artist when it comes to your face. She will suggest what will help and what won't be beneficial to you . You can trust her! She is a medical doctor who knows what she is doing. Her aesthetics  and experience are crucial for the best possible outcome. You have one face. Go to someone trusted, professional and experienced. Why would you go anywhere else ? I love love love the microneedling, botox and filler! Call christine for a consult ... best decision you will ever make.",
    reviewer: "Abby K.",
    stars: 5,
    platform: "Yelp",
    link: "https://www.yelp.com/biz/shotdocmd-san-jose-2?hrid=y-NiaIWapZ8r1cn5OlkTqA&utm_campaign=www_review_share_popup&utm_medium=copy_link&utm_source=(direct)",
  },
  {
    review:
      "Dr Kim was amazing and very friendly. I am truly pleased with the outcome. My skin looks brand new. The process is very transparent and the doctor was very clear and concise in her explanation of the process as well as the after care for my skin. I’ll definitely be back.",
    reviewer: "Corey H.",
    stars: 5,
    platform: "Google Reviews",
    link: "",
  },
  {
    review:
      "I can not say enough about Dr. Kim - she is wonderful! After seeing her, I wouldn’t go anywhere else. To start, her office is inviting and clean. Christina is so kind and welcoming - she checks in to make sure you feel comfortable before and after every visit. And Dr. Kim is a true professional...",
    reviewer: "Amy F.",
    stars: 5,
    platform: "Google Reviews",
    link: "",
  },
  {
    review:
      "Dr. Kim and Christina are wonderful!  I have been to see Dr. Kim twice, the first time for tear troughs, the second time for tear troughs and midface filler. I was extremely pleased with the results on both visits. Christina is pleasant and personable, making me feel at ease upon entering the office. She applied the numbing cream, explained the treatment and answered any questions that I had. Dr. Kim put me at ease, and made pleasant conversation while injecting the filler.  I felt very comfortable and quite confident that I was being treated by a professional.  Dr. Kim kept me informed every step of the way about what type of filler she was using, and she listened to my concerns.  I left the office looking and feeling (most importantly) great!!  I told Dr. Kim that she was an artist; I did not look overdone or fake but instead looked like a less tired and more vibrant version of myself. Also, there was no bruising the next day (and if there was I still would have been very happy). SO happy that I found this practice.",
    reviewer: "Sheila B.",
    stars: 5,
    platform: "Yelp",
    link: "https://www.yelp.com/biz/shotdocmd-san-jose-2?hrid=D1EuKONP0QtVxE0zl8qTWg&utm_campaign=www_review_share_popup&utm_medium=copy_link&utm_source=(direct)",
  },
];


const ReviewsScreen = () => {

useEffect(() => {
  document.title = "Reviews - ShotDocMD";
}, []);

    return (
      <div style={{ marginBottom: "5rem" }}>
        <div className="contain">
          <h1>Reviews</h1>
        </div>
        <FadeIn>
          {" "}
          <Reviews reviews={reviews} background={false} />
        </FadeIn>
      </div>
    );
};

export default ReviewsScreen;