import React, { useEffect, useState } from "react";
import axios from 'axios';
import styled from "styled-components";
import { COLORS } from "../utils/colors";




const ModalFlex = styled.div`
  display: flex;
  flex-direction: column;
`
const ModalContainer = styled.section`
  position: fixed;
  background: white;
  width: 100%;
  max-width: 800px;
  min-height: 200px;
  height: auto;
  max-height: 100vh;
  border-radius: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  overflow: auto;
`

const baseUrl = "https://api.shotdocslo.com";

const SpecialsModal = (props) => {
  const [specials, setSpecials] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    const getSpecials = async () => {
      try {
        const { data } = await axios.get(`${baseUrl}/specials/all`);
        setSpecials(data)
      } catch (error) {
        setError(error);
      }
    }
    getSpecials();
  }, [])

  return (
    <div
      className={
        props.show ? "modal display-block fade-in-fast" : "modal display-none"
      }
    >
      <ModalContainer>
        <ModalFlex>
          <div>
            <div className="align-right">
              <button type="button" onClick={props.handleClose}>
                <div className="times-margin">
                  <i className="fas fa-times"></i>
                </div>
              </button>
            </div>
            <h1 className="green-text modal-title" style={{ marginBottom: '20px' }}>Current Specials</h1>
            {/* <h2 className="specials-subheading">
            Book by December 31st to get in on these hot deals!
          </h2> */}
          </div>
          <div
            className="specials-container"
          >
            {!!specials.length ? (
              specials.map((special, id) => (
                <Special {...{ special, key: id }} />
              ))
            ) : error ? <div className="error-message center">{error}</div> : (
              <div style={{ textAlign: "center" }}>
                There are currently no specials. Check back soon as we update
                our specials frequently.
              </div>
            )}
          </div>
        </ModalFlex>
      </ModalContainer>
    </div>
  );
};

const TitleContainer = styled.h3`
  font-size: 19px;
  font-weight: 600;
  margin-bottom: 0.5rem;
`
const DescriptionContainer = styled.div`
  font-size: 16px;
  color: ${COLORS.primaryAlt};
`
const PriceContainer = styled.div`
  font-size: 16px;
  color: ${COLORS.primary};
`

const Row = styled.div`
  display: flex;
  width: 100%;
  max-width: 700px;
  padding: 0 20px;
`


const Special = ({ special }) => {
  const { title, description, price, image } = special;
  return (
    <div style={{ marginBottom: "3rem" }}>
      <Row>
        <img
          src={image}
          alt="specials"
          className="img-special"
        />
        <div>
          <TitleContainer>
            {title}
          </TitleContainer>
          <DescriptionContainer>
            {description}
          </DescriptionContainer>
          <PriceContainer>
            {price}
          </PriceContainer>
        </div>
      </Row>
    </div>
  )
}



export default SpecialsModal;


