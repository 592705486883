import React, { useEffect } from "react";
import FadeIn from "../components/FadeIn";

const AppointmentsScreen = () => {
  useEffect(() => {
    document.title = "Appointments - ShotDocMD";
  }, []);
  return (
    <div className="contain" style={{ marginBottom: "4rem" }}>
      <h1>Appointments</h1>
      <div className="center">
        <div className="column">
          <FadeIn>
            <div className="subheading">
              Are you looking for treatments such as Botox, Dermal Fillers,
              Microneedling, PRF Facial Rejuvenation, and more near Los Gatos,
              CA? Book an Appointment Now with ShotDocMD.
            </div>
            <div className="center">
              {" "}
              <a
                href="https://clients.mindbodyonline.com/classic/ws?studioid=598760&stype=-9"
                className="nav-button center"
                target="_blank"
                rel="noreferrer"
                style={{ marginTop: "4rem", width: "100%" }}
              >
                BOOK NOW
              </a>
            </div>
          </FadeIn>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default AppointmentsScreen;
