import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../images/shotdoc_logo.png";
import { BOOKING_URL } from "../utils/constants";

const NavBar = () => {
  const [mobileDropdown, setMobileDropdown] = useState(false);
  const primary = "#F16681";
  return (
    <>
      <nav>
        <Link to="/" className="logo-container">
          <img src={logo} alt={logo} className="logo" />
        </Link>
        <div className="nav-row">
          <NavLink
            to="/"
            className="nav-item"
            activeStyle={{ color: primary }}
            exact
          >
            HOME
          </NavLink>
          <a
            href="https://shop.shotdocmd.com"
            className="nav-item"
            target="_blank"
            rel="noreferrer"
          >
            SHOP
          </a>
          <div className="dropdown">
            <NavLink
              to="/services"
              className="nav-item"
              activeStyle={{ color: primary }}
            >
              SERVICES
              <i
                className="fas fa-angle-down"
                style={{ marginLeft: "0.2rem" }}
              ></i>
            </NavLink>
            <div className="dropdown-content">
              <div className="dropdown-column">
                <NavLink
                  to="/services/botox"
                  className="dropdown-link"
                  activeStyle={{
                    color: primary,
                  }}
                >
                  BOTOX®
                </NavLink>
                <NavLink
                  to="/services/injectables"
                  className="dropdown-link"
                  activeStyle={{
                    color: primary,
                  }}
                >
                  INJECTABLES
                </NavLink>
                <NavLink
                  to="/services/microneedling"
                  className="dropdown-link"
                  activeStyle={{
                    color: primary,
                  }}
                >
                  MICRONEEDLING
                </NavLink>
                <NavLink
                  to="/services/melasma"
                  className="dropdown-link"
                  activeStyle={{
                    color: primary,
                  }}
                >
                  MELASMA AND HYPERPIGMENTATION
                </NavLink>
                <NavLink
                  to="/services/prf-facelift"
                  className="dropdown-link"
                  activeStyle={{
                    color: primary,
                  }}
                >
                  PRF FACELIFT
                </NavLink>
                <NavLink
                  to="/services/kybella"
                  className="dropdown-link"
                  activeStyle={{
                    color: primary,
                  }}
                >
                  KYBELLA
                </NavLink>
                {/* <NavLink
                  to="/services/prf"
                  className="dropdown-link"
                  activeStyle={{ color: primary }}
                >
                  PRF LIQUID FACE
                </NavLink> */}
                <NavLink
                  to="/services/vi-peel"
                  className="dropdown-link"
                  activeStyle={{
                    color: primary,
                  }}
                >
                  VI PEEL
                </NavLink>
                <NavLink
                  to="/services/hyperhidrosis"
                  className="dropdown-link"
                  activeStyle={{
                    color: primary,
                  }}
                >
                  HYPERHIDROSIS
                </NavLink>
              </div>
            </div>
          </div>
          <div className="dropdown">
            <NavLink
              to="/resources"
              className="nav-item"
              activeStyle={{ color: primary }}
            >
              RESOURCES
              <i
                className="fas fa-angle-down"
                style={{ marginLeft: "0.2rem" }}
              ></i>
            </NavLink>
            <div className="dropdown-content">
              <div className="dropdown-column">
                <NavLink
                  to="/resources/policies-and-procedures"
                  className="dropdown-link"
                  activeStyle={{ color: primary }}
                >
                  POLICIES AND PROCEDURES
                </NavLink>
                <NavLink
                  to="/resources/forms"
                  className="dropdown-link"
                  activeStyle={{
                    color: primary,
                  }}
                >
                  PATIENT FORMS
                </NavLink>
                {/* <NavLink
                  to="/resources/pricing"
                  className="dropdown-link"
                  activeStyle={{
                    color: primary,
                  }}
                >
                  PRICING
                </NavLink> */}
                <NavLink
                  to="/resources/reviews"
                  className="dropdown-link"
                  activeStyle={{
                    color: primary,
                  }}
                >
                  REVIEWS
                </NavLink>
                {/* <NavLink
                  to="/resources/virtual-tour"
                  className="dropdown-link"
                  activeStyle={{
                    color: primary,
                  }}
                >
                  VIRTUAL TOUR
                </NavLink> */}
              </div>
            </div>
          </div>
          <div className="dropdown">
            <NavLink
              to="/contact"
              className="nav-item"
              activeStyle={{ color: primary }}
            >
              CONTACT US
              <i
                className="fas fa-angle-down"
                style={{ marginLeft: "0.2rem" }}
              ></i>
            </NavLink>
            <div className="dropdown-content">
              <div className="dropdown-column">
                <NavLink
                  to="/contact/directions"
                  className="dropdown-link"
                  activeStyle={{
                    color: primary,
                  }}
                >
                  GET DIRECTIONS
                </NavLink>
                <NavLink
                  to="/contact/about"
                  className="dropdown-link"
                  activeStyle={{
                    color: primary,
                  }}
                >
                  ABOUT US
                </NavLink>
                <NavLink
                  to="/contact/hours"
                  className="dropdown-link"
                  activeStyle={{
                    color: primary,
                  }}
                >
                  BUSINESS HOURS
                </NavLink>
              </div>
            </div>
          </div>
          <a
            className="nav-button"
            href={BOOKING_URL}
            target="_blank"
            rel="noreferrer"
          >
            BOOK NOW
          </a>
        </div>
        <button
          className="nav-toggle"
          onClick={() => {
            setMobileDropdown(true);
          }}
        >
          <i className="fas fa-bars"></i>
        </button>
      </nav>

      <aside className={mobileDropdown ? "dropdown-mobile" : ""}>
        <div className="align-right">
          <i
            className="fas fa-times"
            style={{
              margin: "1.5rem 1.5rem 0 0",
            }}
            onClick={() => setMobileDropdown(false)}
          ></i>
        </div>
        <div className="dropdown-mobile-container">
          <NavLink
            to="/"
            className="dropdown-mobile-nav-link"
            activeStyle={{ color: primary }}
            exact
            onClick={() => setMobileDropdown(false)}
          >
            HOME
          </NavLink>
          <a
            href={"https://shop.shotdocmd.com"}
            className="dropdown-mobile-nav-link"
            target="_blank"
            rel="noreferrer"
            onClick={() => setMobileDropdown(false)}
          >
            SHOP
          </a>
          {/* next category */}
          <NavLink
            to="/services"
            className="dropdown-mobile-nav-link"
            activeStyle={{ color: primary }}
            onClick={() => setMobileDropdown(false)}
          >
            SERVICES
          </NavLink>
          <NavLink
            to="/services/botox"
            className="dropdown-mobile-nav-link dropdown-mobile-nav-link-indented"
            activeStyle={{ color: primary }}
            onClick={() => setMobileDropdown(false)}
          >
            BOTOX®
          </NavLink>
          <NavLink
            to="/services/injectables"
            className="dropdown-mobile-nav-link dropdown-mobile-nav-link-indented"
            activeStyle={{ color: primary }}
            onClick={() => setMobileDropdown(false)}
          >
            INJECTABLES
          </NavLink>
          <NavLink
            to="/services/microneedling"
            className="dropdown-mobile-nav-link dropdown-mobile-nav-link-indented"
            activeStyle={{ color: primary }}
            onClick={() => setMobileDropdown(false)}
          >
            MICRONEEDLING
          </NavLink>
          <NavLink
            to="/services/melasma"
            className="dropdown-mobile-nav-link dropdown-mobile-nav-link-indented"
            activeStyle={{ color: primary }}
            onClick={() => setMobileDropdown(false)}
          >
            MELASMA AND HYPERPIGMENTATION
          </NavLink>
          <NavLink
            to="/services/prf-facelift"
            className="dropdown-mobile-nav-link dropdown-mobile-nav-link-indented"
            activeStyle={{ color: primary }}
            onClick={() => setMobileDropdown(false)}
          >
            PRF FACELIFT
          </NavLink>
          <NavLink
            to="/services/kybella"
            className="dropdown-mobile-nav-link dropdown-mobile-nav-link-indented"
            activeStyle={{ color: primary }}
            onClick={() => setMobileDropdown(false)}
          >
            KYBELLA
          </NavLink>
          {/* <NavLink
            to="/services/prf"
            className="dropdown-mobile-nav-link dropdown-mobile-nav-link-indented"
            activeStyle={{ color: primary }}
            onClick={() => setMobileDropdown(false)}
          >
            PRF LIQUID FACE
          </NavLink> */}
          <NavLink
            to="/services/vi-peel"
            className="dropdown-mobile-nav-link dropdown-mobile-nav-link-indented"
            activeStyle={{ color: primary }}
            onClick={() => setMobileDropdown(false)}
          >
            VI PEEL
          </NavLink>
          <NavLink
            to="/services/hyperhidrosis"
            className="dropdown-mobile-nav-link dropdown-mobile-nav-link-indented"
            activeStyle={{ color: primary }}
            onClick={() => setMobileDropdown(false)}
          >
            HYPERHIDROSIS
          </NavLink>
          {/* next category */}
          <NavLink
            to="/resources"
            className="dropdown-mobile-nav-link"
            activeStyle={{ color: primary }}
            onClick={() => setMobileDropdown(false)}
          >
            RESOURCES
          </NavLink>
          <NavLink
            to="/resources/policies-and-procedures"
            className="dropdown-mobile-nav-link dropdown-mobile-nav-link-indented"
            activeStyle={{ color: primary }}
            onClick={() => setMobileDropdown(false)}
          >
            POLICIES AND PROCEDURES
          </NavLink>
          <NavLink
            to="/resources/forms"
            className="dropdown-mobile-nav-link dropdown-mobile-nav-link-indented"
            activeStyle={{ color: primary }}
            onClick={() => setMobileDropdown(false)}
          >
            PATIENT FORMS
          </NavLink>
          {/* <NavLink
            to="/resources/pricing"
            className="dropdown-mobile-nav-link dropdown-mobile-nav-link-indented"
            activeStyle={{ color: primary }}
            onClick={() => setMobileDropdown(false)}
          >
            PRICING
          </NavLink> */}
          <NavLink
            to="/resources/reviews"
            className="dropdown-mobile-nav-link dropdown-mobile-nav-link-indented"
            activeStyle={{ color: primary }}
            onClick={() => setMobileDropdown(false)}
          >
            REVIEWS
          </NavLink>
          {/* <NavLink
            to="/resources/virtual-tour"
            className="dropdown-mobile-nav-link dropdown-mobile-nav-link-indented"
            activeStyle={{ color: primary }}
            onClick={() => setMobileDropdown(false)}
          >
            VIRTUAL TOUR
          </NavLink> */}
          {/* next category */}
          <NavLink
            to="/contact"
            className="dropdown-mobile-nav-link"
            activeStyle={{ color: primary }}
            onClick={() => setMobileDropdown(false)}
          >
            CONTACT US
          </NavLink>
          <NavLink
            to="/contact/directions"
            className="dropdown-mobile-nav-link dropdown-mobile-nav-link-indented"
            activeStyle={{ color: primary }}
            onClick={() => setMobileDropdown(false)}
          >
            DIRECTIONS
          </NavLink>
          <NavLink
            to="/contact/about"
            className="dropdown-mobile-nav-link dropdown-mobile-nav-link-indented"
            activeStyle={{ color: primary }}
            onClick={() => setMobileDropdown(false)}
          >
            ABOUT US
          </NavLink>
          <NavLink
            to="/contact/hours"
            className="dropdown-mobile-nav-link dropdown-mobile-nav-link-indented"
            activeStyle={{ color: primary }}
            onClick={() => setMobileDropdown(false)}
          >
            BUSINESS HOURS
          </NavLink>
        </div>
        <div className="book-button-container">
          <a
            href={BOOKING_URL}
            style={{ textDecoration: "none" }}
            onClick={() => setMobileDropdown(false)}
            target="_blank"
            rel="noreferrer"
            className="nav-button-mobile"
          >
            BOOK NOW
          </a>
        </div>
      </aside>
    </>
  );
};

export default NavBar;
