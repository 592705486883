import Axios from 'axios';
import { CONTACT_FORM_FAIL, CONTACT_FORM_REQUEST, CONTACT_FORM_SUCCESS, EMAIL_LIST_FAIL, EMAIL_LIST_REQUEST, EMAIL_LIST_SUCCESS } from "../constants/contactConstants";

export const axiosInstance = Axios.create({
    withCredentials: true,
    baseURL: "https://api.shotdocslo.com",
})


export const sendContactForm = (form) => async (dispatch) => {
    dispatch({ type: CONTACT_FORM_REQUEST });
    try {
      const { data } = await axiosInstance.post("/info-contact", {form}
      );
      dispatch({ type: CONTACT_FORM_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: CONTACT_FORM_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
}
export const sendEmailList = (form) => async (dispatch) => {
    dispatch({ type: EMAIL_LIST_REQUEST });
    try {
      const { data } = await axiosInstance.post("/info-contact/email", {form}
      );
      dispatch({ type: EMAIL_LIST_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: EMAIL_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
}