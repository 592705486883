import { CONTACT_FORM_FAIL, CONTACT_FORM_REQUEST, CONTACT_FORM_SUCCESS, EMAIL_LIST_FAIL, EMAIL_LIST_REQUEST, EMAIL_LIST_SUCCESS } from "../constants/contactConstants";

export const contactFormSendReducer = (state = {}, action) => {
  switch (action.type) {
    case CONTACT_FORM_REQUEST:
      return { loading: true };
    case CONTACT_FORM_SUCCESS:
      return { loading: false, success: true };
    case CONTACT_FORM_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const emailListSendReducer = (state = {}, action) => {
  switch (action.type) {
    case EMAIL_LIST_REQUEST:
      return { loading: true };
    case EMAIL_LIST_SUCCESS:
      return { loading: false, success: true };
    case EMAIL_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
