import React from 'react';
import { Link } from 'react-router-dom';

const CardSquare = (props) => {
    return (
      <Link to={props.link}>
        <div
          className="card card-img"
          style={{
            width: `${props.width}`,
            maxWidth: `${props.width}`,
            minWidth: `${props.width}`,
            position: 'relative',
            textAlign: 'center',
          }}
        >
          <img
            src={props.image}
            alt="card"
            style={{ maxWidth: "100%", maxHeight: "100%", width: "100%", height: '100%', objectFit: 'cover', opacity: '0.7' }}
            className='card-img'
          />
          <div className={props.color === 'alt' ? 'card-text-alt': 'card-text'}>{props.text}</div>
        </div>
      </Link>
    );
};

export default CardSquare;