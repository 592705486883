import React from "react";

const PreNav = () => {
  return (
    <div className="pre-nav">
      <div className="pre-nav-web">
        <a className="pre-nav-link" href="tel:8052351400">
          <i className="fas fa-phone"></i> Call Now (805)235-1400
        </a>
        <a
          className="pre-nav-link"
          href="sms:8052351400"
          style={{ marginLeft: "2rem" }}
        >
          <i className="fas fa-sms"></i> Text Us (805)235-1400
        </a>
        <a
          className="pre-nav-link"
          href="mailto:info@shotdocmd.com"
          style={{ marginLeft: "2rem" }}
        >
          <i className="fas fa-envelope"></i> Email info@shotdocmd.com
        </a>
      </div>
      <div className="pre-nav-mobile">
        <a className="pre-nav-link" href="tel:8052351400">
          <i className="fas fa-phone"></i> Call Now
        </a>
        <a className="pre-nav-link" href="sms:8052351400">
          <i className="fas fa-sms"></i> Text Now
        </a>
        <a className="pre-nav-link" href="mailto:info@shotdocmd.com">
          <i className="fas fa-envelope"></i> Email Now
        </a>
      </div>
    </div>
  );
};

export default PreNav;
