import React, { useEffect } from "react";
import Card from "../components/CardSquare";
import fifth from '../images/card5.png';
import second from '../images/card2.png';
import sixth from '../images/card6.png';
import ContactForm from "../components/ContactForm";
import { useSelector } from "react-redux";
import FadeIn from "../components/FadeIn";

const ContactScreen = () => {

    useEffect(() => {
      document.title = "Contact ShotDocMD";
    }, []);

  const contactFormSend = useSelector((state) => state.contactFormSend);
  const { success } = contactFormSend;

  return (
    <div className="contain">
      <h1>Contact ShotDocMD</h1>
      <div className="contain-form" style={{ marginBottom: "5rem" }}>
        {" "}
        <ContactForm small={false} />
      </div>

      {success && (
        <div>
          <h2 style={{textAlign: 'center'}}>Other Resources</h2>

          <div
            className="row-full fade-in"
            style={{ justifyContent: "space-evenly" }}
          >
            {" "}
            <FadeIn>
              {" "}
              <Card image={fifth} link="/contact/directions" text='DIRECTIONS' />
            </FadeIn>
            <FadeIn>
              {" "}
              <Card image={second} link="/contact/about" text='ABOUT' />
            </FadeIn>
            <FadeIn>
              {" "}
              <Card image={sixth} link="/contact/hours" text="BUSINESS HOURS" />
            </FadeIn>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactScreen;
