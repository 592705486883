import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch, useSelector } from "react-redux";
import { sendContactForm } from "../actions/contactActions";
import Spinner from "react-bootstrap/Spinner";
import FadeIn from "./FadeIn";


const ContactForm = (props) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [canSubmit, setCanSubmit] = useState(false);
  const [errorMessage, setError] = useState("");


  const contactFormSend = useSelector((state) => state.contactFormSend);
  const { success, loading, error } = contactFormSend;

  const dispatch = useDispatch();


  const submitHandler = (e) => {
    e.preventDefault();
    if (!firstName || !lastName || !email || !message || !phone) {
      setError("All fields must be filled out.");
    } else {
      dispatch(sendContactForm({
          firstName,
          lastName,
          email,
          phone,
          message,
      }))
    }
  };

  function onChange(value) {
    setCanSubmit(true);
  }

  return (
      <FadeIn>
        <div
          className={props.small ? "column contact-form-container" : "column"}
        >
          {props.small && <h1 className="center">Contact Us</h1>}
          {success ? (
            <div className="success-message fade-in">
              Your form has been successfully submitted
            </div>
          ) : (
            <form onSubmit={(e) => submitHandler(e)}>
              <div className="label-input column">
                <label htmlFor="firstName">First Name</label>
                <input
                  id="firstName"
                  type="text"
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                    setError("");
                  }}
                  placeholder="Your First Name"
                />
              </div>
              <div className="label-input column">
                <label htmlFor="lastName">Last Name</label>
                <input
                  id="lastName"
                  type="text"
                  value={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value);
                    setError("");
                  }}
                  placeholder="Your Last Name"
                />
              </div>
              <div className="label-input column">
                <label htmlFor="email">Email Address</label>
                <input
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setError("");
                  }}
                  placeholder="Your Email"
                />
              </div>
              <div className="label-input column">
                <label htmlFor="phone">Phone Number</label>
                <input
                  id="phone"
                  type="tel"
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                    setError("");
                  }}
                  placeholder="Your Phone Number"
                />
              </div>
              <div className="label-input column">
                <label htmlFor="message">Message</label>
                <textarea
                  id="message"
                  type="text"
                  value={message}
                  onChange={(e) => {
                    setMessage(e.target.value);
                    setError("");
                  }}
                  placeholder="Your Message"
                  rows="3"
                ></textarea>
              </div>
              <ReCAPTCHA
                sitekey="6LehJbEaAAAAABLrB9PGdnWSYNyy-vcbp_iEM_8Q"
                onChange={onChange}
                className="label-input center"
                size='normal'
              />
              <div className="error-message center">{errorMessage}</div>
              <div className="error-message center">{error}</div>
              <button
                type="submit"
                className="submit-button"
                disabled={!canSubmit ? true : false}
              >
                {loading ? (
                  <Spinner animation="border" variant="light" />
                ) : (
                  "Submit"
                )}
              </button>
            </form>
          )}
        </div>
      </FadeIn>
  );
};

export default ContactForm;
