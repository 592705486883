import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import FadeIn from "../components/FadeIn";

const NotFoundScreen = () => {
  useEffect(() => {
    document.title = "ShotDocMD";
  }, []);

  return (
    <div className="contain">
      <div className="center">
        {" "}
        <div>
          <FadeIn>
            <h1>Page Not Found</h1>
            <div className="subheading">
              The page you are looking for cannot be found.
            </div>
            <div className="center" style={{marginTop: '2rem'}}>
              <Link to="/">
                <button className="button-more">Return to Home</button>
              </Link>
            </div>
          </FadeIn>
        </div>
      </div>
    </div>
  );
};

export default NotFoundScreen;
