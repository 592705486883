import React from 'react';
import { BOOKING_URL } from '../utils/constants';

const BookNowButton = () => {
    return (
      <a href={BOOKING_URL} target='_blank' rel='noreferrer'>
        <button className="book-button-full">BOOK NOW</button>
      </a>
    );
};

export default BookNowButton;