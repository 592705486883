import React, { useEffect } from 'react';
import Card from '../components/CardSquare';
import FadeIn from '../components/FadeIn';
import first from "../images/card1.png";
import second from "../images/card2.png";
import third from "../images/card3.png";
import fourth from "../images/card4.png";
import fifth from "../images/card5.png";
import sixth from "../images/card6.png";
import seventh from "../images/card7.png";
import eighth from "../images/card8.png";

const ServicesScreen = () => {

useEffect(() => {
  document.title = "Services - ShotDocMD";
}, []);

    return (
      <div className="contain">
        <h1>Services</h1>
        <div className="row" style={{ justifyContent: "space-evenly" }}>
          {" "}
          <FadeIn>
            {" "}
            <Card
              image={fifth}
              link="/services/botox"
              text="BOTOX®"
              color="alt"
            />
          </FadeIn>
          <FadeIn>
            {" "}
            <Card
              image={third}
              link="/services/injectables"
              text="INJECTABLES"
            />
          </FadeIn>
          <FadeIn>
            {" "}
            <Card
              image={fourth}
              link="/services/microneedling"
              text="MICRONEEDLING"
              color="alt"
            />
          </FadeIn>
          <FadeIn>
            {" "}
            <Card
              image={sixth}
              link="/services/melasma"
              text="MELASMA"
              color="alt"
            />
          </FadeIn>
          <FadeIn>
            {" "}
            <Card
              image={seventh}
              link="/services/prf-facelift"
              text="PRF FACELIFT"
              color="alt"
            />
          </FadeIn>
          <FadeIn>
            {" "}
            <Card
              image={first}
              link="/services/kybella"
              text="KYBELLA®"
            />
          </FadeIn>
          <FadeIn>
            {" "}
            <Card
              image={second}
              link="/services/vi-peel"
              text="VI PEEL"
            />
          </FadeIn>
          <FadeIn>
            {" "}
            <Card
              image={eighth}
              link="/services/hyperhidrosis"
              text="HYPERHIDROSIS"
              color="alt"
            />
          </FadeIn>
        </div>
      </div>
    );
};

export default ServicesScreen;