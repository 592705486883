import React from "react";
import first from '../images/card1.png';
import second from '../images/card2.png';
import third from '../images/card3.png';
import fourth from '../images/card4.png';
import fifth from '../images/card5.png';
import sixth from '../images/card6.png';
import seventh from '../images/card7.png';
import eighth from '../images/card8.png';

import CardSquare from "./CardSquare";

const FeaturedServices = () => {
  return (
    <div className="center" style={{ marginTop: "4rem", marginBottom: "4rem" }}>
      <div className="column">
        <h2 className="center">OUR SERVICES</h2>
        <div className="row-card">
          <CardSquare
            image={fifth}
            link="/services/botox"
            width="20rem"
            text="BOTOX®"
          />

          <CardSquare
            image={third}
            link="/services/injectables"
            width="20rem"
            text="INJECTABLES"
          />

          <CardSquare
            image={fourth}
            link="/services/microneedling"
            width="20rem"
            text="MICRONEEDLING"
          />

          <CardSquare
            image={sixth}
            link="/services/melasma"
            width="20rem"
            text="MELASMA"
          />
        
          <CardSquare
            image={seventh}
            link="/services/prf-facelift"
            width="20rem"
            text="PRF Facelift"
            // color="alt"
          />

          <CardSquare
            image={first}
            link="/services/kybella"
            width="20rem"
            text="KYBELLA®"
          />

          <CardSquare
            image={second}
            link="/services/vi-peel"
            width="20rem"
            text="VI PEEL"
          />

          <CardSquare
            image={eighth}
            link="/services/hyperhidrosis"
            width="20rem"
            text="HYPERHIDROSIS"
          />
          </div>
      </div>
    </div>
  );
};

export default FeaturedServices;
