import React, { useEffect } from 'react';
import pdf from "../assets/patient-forms.pdf";
import FadeIn from '../components/FadeIn';


const PatientFormsScreen = () => {

useEffect(() => {
  document.title = "Patient Forms - ShotDocMD";
}, []);

    return (
      <div className="contain">
        <h1>Patient Forms</h1>
        <FadeIn>
          <div
            className="center"
            style={{ marginTop: "3rem", marginBottom: "3rem" }}
          >
            <div className='column'>
              {" "}
              {/* <embed src={pdf} className="pdf" /> */}
              <object
                data={pdf}
                type="application/pdf"
                width="100%"
                className="pdf"
              >
                {/* <p>
                  Your web browser doesn't have a PDF plugin. Instead you can{" "}
                  <a href={pdf}>click here to download the PDF file.</a>
                </p> */}
              </object>
              <div className="pdf-mobile">
                {" "}
                <a href={pdf} target="_blank" rel="noreferrer" className='link'>
                  Click Here to Open the Patient Forms
                </a>
              </div>
            </div>
          </div>
        </FadeIn>
      </div>
    );
};

export default PatientFormsScreen;