import React, { useEffect } from "react";
import image from "../images/carousel5.jpg";
import FadeInSection from "../components/FadeInSection";
import ContactForm from "../components/ContactForm";
import MailListForm from "../components/MailListForm";
import Directions from "../components/Directions";
import FadeIn from "../components/FadeIn";

const PRFFaceliftScreen = () => {
  useEffect(() => {
    document.title = "Platelet Rich Fibrin (PRF) Facelift - ShotDocMD";
  }, []);

  return (
    <div className="contain">
      <div className="info-screen-container">
        <div className="info-screen-main">
          <FadeIn>
            <h1>Platelet RIch Fibrin (PRF) Facelift</h1>

            <div className="subheading">
              Are you looking for a PRF facelift procedure near Los Gatos, CA?
            </div>
          </FadeIn>
          <br></br>
          <FadeInSection>
            {" "}
            <div style={{ margin: "1rem 0 3rem 0" }}>
              <img src={image} alt="botox" className="image" />
            </div>
          </FadeInSection>
          <FadeInSection>
            {" "}
            <h2 style={{ marginTop: "3rem", textAlign: "center" }}>
              What is the PRF Facelift and how can it help <strong>you</strong>?
            </h2>
          </FadeInSection>

          <FadeInSection>
            {" "}
            <div className="text-med">
              The PRF Facelift is a <strong>nonsurgical</strong> treatment that
              uses both hyaluronic filler and PRF to “lift” and restore volume
              back into the face. The ideal candidate is someone who has some
              loss of volume in the cheeks and under the eyes and some color and
              texture changes
            </div>
          </FadeInSection>
          <br></br>

          <FadeInSection>
            <div className="text-med">
              The PRF Facelift™ is a safe procedure for renewing the skin of the
              face and for correcting shape. Dr. Grace Kim, M.D. uses an HA
              (hyaluronic acid) filler to sculpt a naturally beautiful face,
              then uses PRF to polish the sculpture for improved color, texture
              and shape.
            </div>
          </FadeInSection>
          <FadeInSection>
            <div className="text-med">
              This treatment is natural in that your own cells are used. Since a
              distillate of growth factors from your own blood (PRFM or platelet
              rich fibrin matrix) is used, there should be no side effects from
              the material injected.
            </div>
          </FadeInSection>
          <br></br>
          <FadeInSection>
            <div className="text-med">
              The body reacts to the treated cells as it does to a wound and
              immediately starts repairing the tissue. This builds the
              underlying tissue with tightening, smoothing, and increased blood
              flow (which makes the color more attractive). You should see
              improvements immediately, although there is usually a return to
              prior treatment status in 3-5 days as the water is absorbed and
              prior to the complete action of the cellular regenerative process.
            </div>
          </FadeInSection>
          <br></br>

          <FadeInSection>
            <div className="text-med">
              Within 2-4 weeks you will see improvement with continued changes
              for 12 weeks. There&#39;s actual growth of new tissue by
              stimulation of uni-potent stem cells, so the change is not from
              something foreign being in the body but from the body actually
              rejuvenating and growing. The PRFM stimulates new blood flow with
              new blood vessels.
            </div>
          </FadeInSection>
          <br></br>
          <FadeInSection>
            <div className="text-med">
              The results of this treatment can last, but results may vary and
              research documenting the longevity of results is ongoing. As with
              all treatments, surgical and nonsurgical, the body reacts to sun,
              stress, diet, smoke, lack of sufficient sleep, and pollution by
              breaking down the collagen and elastin in the skin. We are exposed
              to many of these factors daily but we can limit their impact on
              our lives. Smokers have less ……………treatment than non-smokers,
              since the toxins in cigarette smoke block the response of the stem
              cells.
            </div>
          </FadeInSection>
          <br></br>
          <FadeInSection>
            <div className="text-med">
              <u>
                There may be some variation in achieving the results requested
                as everyone’s body type is different and may have a different
                response.
              </u>{" "}
              As with all cosmetic medicine treatments, maintenance of your
              skin, using chemical peels and skin care topical treatments are
              additional ways to improve results. Call us today to see if you
              are a good candidate for the PRF Facelift treatment.
            </div>
          </FadeInSection>
        </div>
        <div className="info-screen-side">
          <div style={{ marginBottom: "3rem" }}>
            {" "}
            <ContactForm small={true} />
          </div>
          <div style={{ marginBottom: "3rem" }}>
            <Directions />
          </div>
          <MailListForm small={true} />
        </div>
      </div>
    </div>
  );
};

export default PRFFaceliftScreen;
