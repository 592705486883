import React from "react";

const Welcome = () => {
  return (
    <div style={{ marginTop: "2rem" }}>
      <div className="center">
        <div className="contain-small column">
          <h1>Welcome to ShotDocMD</h1>
          <div className="med-text">
            Dr. Grace L. Kim, M.D. and her team at ShotDocMD are here to help
            you reach your aesthetic dreams. As a platinum level injector, Dr.
            Kim has helped thousands of people reach their beauty goals through
            treatments such as Botox, Dermal Fillers, Microneedling, PRF Facial
            Rejuvenation, and more. She has been in the Medspa Industry for over
            16 years and has grown with the monumental changes in the
            non-surgical, non-invasive aesthetic industry. She understands
            facial anatomy and can create the most beautiful and natural results
            with a combination of neurotoxin, facial fillers and skin care
            treatments. When you come to ShotDocMD, you will meet, consult with,
            and be treated by Dr Kim. It is her sincere desire to help you look
            and feel your best!
          </div>
          <br></br>
          <div className="med-text">
            Located in Los Gatos, California, ShotDocMD serves patients from
            across the Bay Area. Take your first step in your aesthetic journey
            and book today!
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
