import React, { useEffect } from 'react';
import Card from '../components/CardSquare';
// import forms from '../images/forms.png';
// import reviews from '../images/reviews.png';
// import pricing from '../images/pricing.png';
import first from '../images/card1.png';
import fourth from '../images/card4.png';
// import seventh from '../images/card7.png';
// import third from '../images/card3.png';
import second from '../images/card6.png';
import FadeIn from '../components/FadeIn';


const ResourcesScreen = () => {

useEffect(() => {
  document.title = "Resources - ShotDocMD";
}, []);

    return (
      <div className="contain">
        <h1>Resources</h1>
        <div className="row-full" style={{ justifyContent: "space-evenly" }}>
          <FadeIn>
            <Card image={second} link="/resources/policies-and-procedures" text='POLICIES AND PROCEDURES' />
          </FadeIn>
          <FadeIn>
            <Card image={first} link="/resources/forms" text='PATIENT FORMS' />
          </FadeIn>
          {/* <FadeIn>
            <Card image={seventh} link="/resources/pricing" text='PRICING' color='alt' />
          </FadeIn> */}
          <FadeIn>
            <Card image={fourth} link="/resources/reviews" text='REVIEWS'  />
          </FadeIn>
          {/* <FadeIn>
            <Card image={third} link="/resources/virtual-tour" text='VIRTUAL TOUR'  />
          </FadeIn> */}
        </div>
      </div>
    );
};

export default ResourcesScreen;