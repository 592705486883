import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import HomeScreen from "./screens/HomeScreen";
import NavBar from "./components/NavBar";
import ServicesScreen from "./screens/ServicesScreen";
import BotoxScreen from "./screens/BotoxScreen";
import ResourcesScreen from "./screens/ResourcesScreen";
// import PricingScreen from "./screens/PricingScreen";
import AppointmentsScreen from "./screens/AppointmentsScreen";
// import GetDirectionsScreen from "./screens/GetDirectionsScreen";
// import GetDirectionsScreenSLO from "./screens/GetDirectionsScreenSLO";
import GetDirectionsScreenLosGatos from "./screens/GetDirectionsScreenLosGatos";
import ContactScreen from "./screens/ContactScreen";
import AboutUsScreen from "./screens/AboutUsScreen";
import BusinessHoursScreen from "./screens/BusinessHoursScreen";
import PatientFormsScreen from "./screens/PatientFormsScreen";
import AgingFace from "./components/AgingFace";
import Footer from "./components/Footer";
import FadeInSection from "./components/FadeInSection";
import ReviewsScreen from "./screens/ReviewsScreen";
import HyperhidrosisScreen from "./screens/HyperhidrosisScreen";
import ScrollToTop from "./components/scrollToTop";
import InjectablesScreen from "./screens/InjectablesScreen";
import MicroneedlingScreen from "./screens/MicroneedlingScreen";
import MelasmaScreen from "./screens/MelasmaScreen";
import KybellaScreen from "./screens/KybellaScreen";
import ViPeelScreen from "./screens/ViPeelScreen";
import PreNav from "./components/PreNav";
import NotFoundScreen from "./screens/NotFoundScreen";
// import VirtualTourScreen from "./screens/VirtualTourScreen";
import PoliciesScreen from "./screens/PoliciesScreen";
import PRFFaceliftScreen from "./screens/PRFFaceliftScreen";

function App() {
  return (
    <Router>
      <ScrollToTop>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100%",
          }}
        >
          <PreNav />
          <NavBar />

          {/* ROUTES BELOW HERE */}
          <div style={{ paddingTop: "13rem" }} className="wrapper">
            <Switch>
              <Route path="/" exact>
                <HomeScreen />
              </Route>
              <Route path="/services" exact>
                <ServicesScreen />
              </Route>
              <Route path="/services/botox" exact>
                <BotoxScreen />
              </Route>
              <Route path="/services/injectables" exact>
                <InjectablesScreen />
              </Route>
              <Route path="/services/microneedling" exact>
                <MicroneedlingScreen />
              </Route>
              <Route path="/services/melasma" exact>
                <MelasmaScreen />
              </Route>
              <Route path="/services/prf-facelift" exact>
                <PRFFaceliftScreen />
              </Route>
              <Route path="/services/kybella" exact>
                <KybellaScreen />
              </Route>
              {/* <Route path="/services/prf" exact>
                <PRFScreen />
              </Route> */}
              <Route path="/services/vi-peel" exact>
                <ViPeelScreen />
              </Route>
              <Route path="/services/hyperhidrosis" exact>
                <HyperhidrosisScreen />
              </Route>
              <Route path="/resources" exact>
                <ResourcesScreen />
              </Route>
              {/* <Route path="/resources/location" exact>
                <LocationsScreen />
              </Route> */}
              <Route path="/resources/forms" exact>
                <PatientFormsScreen />
              </Route>
              {/* <Route path="/resources/pricing" exact>
                <PricingScreen />
              </Route> */}
              <Route path="/resources/reviews" exact>
                <ReviewsScreen />
              </Route>
              {/* <Route path="/resources/virtual-tour" exact>
                <VirtualTourScreen />
              </Route> */}
              <Route path="/resources/policies-and-procedures" exact>
                <PoliciesScreen />
              </Route>
              <Route path="/contact" exact>
                <ContactScreen />
              </Route>
              <Route path="/contact/directions" exact>
                <GetDirectionsScreenLosGatos />
              </Route>
              {/* <Route path="/contact/directions/slo" exact>
                <GetDirectionsScreenSLO />
              </Route>
              <Route path="/contact/directions/los-gatos" exact>
                <GetDirectionsScreenLosGatos />
              </Route> */}
              <Route path="/contact/about" exact>
                <AboutUsScreen />
              </Route>
              <Route path="/contact/hours" exact>
                <BusinessHoursScreen />
              </Route>
              <Route path="/appointments" exact>
                <AppointmentsScreen />
              </Route>
              <Route path="/aging-with-grace" exact>
                <AgingFace />
              </Route>
              <Route path="*">
                <NotFoundScreen />
              </Route>
            </Switch>
          </div>
          <FadeInSection>
            {" "}
            <Footer />
          </FadeInSection>
        </div>
      </ScrollToTop>
    </Router>
  );
}

export default App;
