import React, { useEffect } from "react";
import FadeIn from "../components/FadeIn";

const hours = [
  {
    day: "Monday",
    hours: "9:00am - 6:00pm",
    dayCode: 1,
    hoursCode1: 9,
    hoursCode2: 18,
  },
  {
    day: "Tuesday",
    hours: "9:00am - 1:00pm",
    dayCode: 2,
    hoursCode1: 9,
    hoursCode2: 13,
  },
  {
    day: "Wednesday",
    hours: "9:00am - 1:00pm",
    // hours2: "4:00pm - 7:00pm",
    dayCode: 3,
    hoursCode1: 9,
    hoursCode2: 13,
    // hoursCode3: 16,
    // hoursCode4: 19,
  },
  {
    day: "Thursday",
    hours: "9:00am - 1:00pm",
    dayCode: 4,
    hoursCode1: 9,
    hoursCode2: 13,
  },
  {
    day: "Friday",
    hours: "Closed",
    dayCode: 5,
    hoursCode1: 0,
  },
  {
    day: "Saturday",
    hours: "Closed",
    dayCode: 6,
    hoursCode1: 0,
  },
  {
    day: "Sunday",
    hours: "Closed",
    dayCode: 0,
    hoursCode1: 0,
  },
];

const date = new Date();
const day = date.getDay();
const hour = date.getHours();
const mins = date.getMinutes();

const dayObj = hours.find((obj) => {
  return obj.dayCode === day;
});


const { hoursCode1, hoursCode2, hoursCode3, hoursCode4 } = dayObj;

const isOpen =
  (hoursCode1 !== 0 &&
    hoursCode1 <= hour &&
    (hoursCode2 > hour || (hoursCode2 === hour && mins === 0))) ||
  (hoursCode1 !== 0 &&
    hoursCode3 <= hour &&
    (hoursCode4 > hour || (hoursCode4 === hour && mins === 0)))
    ? true
    : false;

const BusinessHoursScreen = () => {
  useEffect(() => {
    document.title = "Business Hours - ShotDocMD";
  }, []);
  return (
    <div className="contain" style={{ marginBottom: "4rem" }}>
      <h1>Business Hours</h1>
      <div className="center">
        <div className="column">
          <FadeIn>
            <div
              className={
                isOpen ? "center is-open day-active" : "center is-open"
              }
            >
              ShotDocMD is {isOpen ? "open" : "closed"} now
            </div>
          </FadeIn>
          <div>
            <FadeIn>
              {hours.map((h) => (
                <div className="hours-row" key={h.dayCode}>
                  <div className={h.dayCode === day ? "day-active" : ""}>
                    {h.day}
                  </div>
                  <div className="column">
                    <div
                      className={
                        (h.dayCode === day)
                          ? "day-active"
                          : ""
                      }
                    >
                      {h.hours}
                    </div>
                    <div
                      className={
                        h.dayCode === day
                          ? "day-active"
                          : ""
                      }
                    >
                      {h.hours2}
                    </div>
                  </div>
                </div>
              ))}
            </FadeIn>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessHoursScreen;
