import React, { useEffect } from "react";
import image from "../images/hyperhidrosis.jpg";
import FadeInSection from "../components/FadeInSection";
import ContactForm from "../components/ContactForm";
import MailListForm from "../components/MailListForm";
import Directions from "../components/Directions";
import FadeIn from "../components/FadeIn";

const HyperhidrosisScreen = () => {
  useEffect(() => {
    document.title = "Hyperhidrosis - ShotDocMD";
  }, []);

  return (
    <div className="contain">
      <div className="info-screen-container">
        <div className="info-screen-main">
          <FadeIn>
            <h1>BOTOX® for Hyperhidrosis</h1>

            <div className="subheading">
              Do you suffer from excessive sweating? ShotDocMD offers BOTOX® for
              Hyperhidrosis in Los Gatos, CA.
            </div>
          </FadeIn>

          <br></br>
          <FadeInSection>
            {" "}
            <div style={{ margin: "1rem 0 3rem 0" }}>
              <img src={image} alt="botox" className="image" />
            </div>
          </FadeInSection>

          <FadeInSection>
            {" "}
            <div className="text-med">
              Sweating is normal. In fact, it’s essential: Humans need to sweat
              in order to regulate body temperature. But some people sweat much
              more than is needed. These individuals have a medical condition
              called Hyperhidrosis.
            </div>
          </FadeInSection>
          <br></br>
          <FadeInSection>
            {" "}
            <div className="text-med">
              Hyperhidrosis, or excessive sweating, can significantly affect a
              person’s quality of life. In some cases, problematic sweating is
              limited to one area of the body, such as the underarms or hands, a
              condition called focal hyperhidrosis. Other cases are more
              generalized, with sweating over large areas of the body.
            </div>
          </FadeInSection>
          <br></br>
          <FadeInSection>
            {" "}
            <div className="text-med">
              The most common areas affected by hyperhidrosis are the scalp,
              underarms, hands, feet and groin area. You may sweat so much that
              it soaks through your clothes or drips off your hands. In addition
              to disrupting normal activities, it also causes anxiety and
              embarrassment.
            </div>
          </FadeInSection>
          <br></br>
          <FadeInSection>
            {" "}
            <div className="text-med">
              BOTOX® — often associated with cosmetic wrinkle reduction — is
              also a particularly effective treatment for focal hyperhidrosis.
              In 2004, the U.S. Food & Drug Administration (FDA) approved BOTOX®
              (onabotulinumtoxinA) for the treatment of severe primary axillary
              hyperhidrosis in patients unable to obtain relief using
              antiperspirants.
            </div>
          </FadeInSection>
          <br></br>
          <FadeInSection>
            {" "}
            <div className="text-med">
              BOTOX® injections use botulinum toxin to block the nerve signals
              to the sweat glands thereby stopping the sweat glands from
              producing too much sweat. Retreatment is typically needed in four
              to six months, as the nerves regenerate. Over time, however,
              patients can go longer between treatments.
            </div>
          </FadeInSection>
          <br></br>
          <FadeInSection>
            {" "}
            <div className="text-med">
              According to the International Hyperhidrosis Society, underarm
              BOTOX® injections can reduce underarm sweating by 82–87%.Results
              start to be noticeable approximately 2 to 4 days after treatment
              with the full effects usually noted within 2 weeks. Dryness
              typically lasts 4 to 12 months.
            </div>
          </FadeInSection>
          <br></br>
          <FadeInSection>
            {" "}
            <div className="text-med">
              At ShotDocMD, Dr. Kim has years of experience treating patient
              with hyperhydrosis. After she cleanses off the area she places
              many small shots of BOTOX® into the targeted area, usually 50
              units per side. We offer topical numbing and ice during the
              treatment. The treatment takes about 30 minutes to complete.
            </div>
          </FadeInSection>
          <br></br>
        </div>
        <div className="info-screen-side">
          <div style={{ marginBottom: "3rem" }}>
            {" "}
            <ContactForm small={true} />
          </div>
          <div style={{ marginBottom: "3rem" }}>
            <Directions />
          </div>
          <MailListForm small={true} />
        </div>
      </div>
    </div>
  );
};

export default HyperhidrosisScreen;
