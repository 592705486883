import React, { useEffect } from "react";
import image from "../images/carousel1.jpg";
import FadeInSection from "../components/FadeInSection";
import ContactForm from "../components/ContactForm";
import MailListForm from "../components/MailListForm";
import Directions from "../components/Directions";
import FadeIn from "../components/FadeIn";

const BotoxScreen = () => {
  useEffect(() => {
    document.title = "Botox® - ShotDocMD";
  }, []);

  return (
    <div className="contain">
      <div className="info-screen-container">
        <div className="info-screen-main">
          <FadeIn>
            <h1>Botox®</h1>

            <div className="subheading">
              Are you looking for Botox injections in Los Gatos, California?
            </div>
          </FadeIn>

          <br></br>
          <FadeInSection>
            {" "}
            <div style={{ margin: "1rem 0 3rem 0" }}>
              <img src={image} alt="botox" className="image" />
            </div>
          </FadeInSection>
          <FadeInSection>
            {" "}
            <div className="text-med">
              After years of squinting, laughing, frowning, smoking and
              worrying, the skin around the eyes and on the forehead often
              develops deep lines and wrinkles. These lines are called
              "expression lines." Expression lines may often make you look
              older, tired or stressed. But thanks to Botox Cosmetic, these
              expression lines can be softened or even eliminated!
            </div>
          </FadeInSection>
          <br></br>
          <FadeInSection>
            {" "}
            <div className="text-med">
              Botox® is a “neuromodulator,” a drug that relaxes targeted facial
              muscles. By gently infiltrating Botox directly into delicate
              muscles that actuate these expressions, muscles are relaxed, and
              wrinkles magically melt away! The effect lasts several months. A
              15 minute office appointment is all you need! Knowledge about
              aesthetics, aging features, and specific anatomy is important for
              the best result. This is what differentiates Dr. Kim from many
              others who inject neuromodulators.
            </div>
          </FadeInSection>
          <FadeInSection>
            {" "}
            <h2 style={{ marginTop: "3rem", textAlign: "center" }}>
              More about Botox® - Common Questions
            </h2>
          </FadeInSection>

          <FadeInSection>
            {" "}
            <h3 style={{ marginTop: "2rem" }}>What is Botox®?</h3>
            <div className="text-med">
              Botox® Cosmetic is a FDA approved purified protein naturally
              derived from the bacterium Clostridium Botulinum Type A. It has
              been used for more than 20 years to reduce unwanted muscle
              activity, and is considered safe and effective. Initially
              introduced for treatment of ophthalmic and neurologic muscular
              diseases, it is now also used for the cosmetic reduction of facial
              expression lines.
            </div>
          </FadeInSection>
          <FadeInSection>
            {" "}
            <h3 style={{ marginTop: "2rem" }}>The Botox® Treatment:</h3>
            <div className="text-med">
              The Botox® treatment typically takes approximately 10 minutes.
              Botox is injected into the area affected by the visible lines with
              a very thin needle. The Botox causes the muscles initiating the
              lines and wrinkles to relax, which reduces the appearance of
              undesirable wrinkles and lines on the skin.
            </div>
          </FadeInSection>
          <FadeInSection>
            {" "}
            <h3 style={{ marginTop: "2rem" }}>How does Botox® work?</h3>
            <div className="text-med">
              Botox® acts by immobilizing the muscle, at treated sites. It does
              this by inhibiting contraction of the targeted muscles. Only those
              muscles producing these undesirable lines are treated.
            </div>
          </FadeInSection>
          <FadeInSection>
            {" "}
            <h3 style={{ marginTop: "2rem" }}>
              What areas can you use Botox® for?
            </h3>
            <div className="text-med">
              Frown lines between the eyebrows, crow’s feet lines and forehead
              wrinkles are effectively treated with Botox®.
            </div>
          </FadeInSection>
          <FadeInSection>
            {" "}
            <h3 style={{ marginTop: "2rem" }}>
              Does the Botox® injection hurt?
            </h3>
            <div className="text-med">
              When Botox® is injected there is a minor sting for a few seconds.
              Once the injection is complete, there is usually no discomfort.
            </div>
          </FadeInSection>
          <FadeInSection>
            {" "}
            <h3 style={{ marginTop: "2rem" }}>
              How does Botox® compare to other products such as collagen and
              filler materials?
            </h3>
            <div className="text-med">
              Unlike filler materials, Botox® treats the underlying cause of the
              facial expression lines. Filler materials, such as Restylane®,
              plump up the skin. Although fillers reduce the appearance of lines
              when there is no facial expression, the moment the person does
              smile or frown, the lines reappear. Botox® actually prevents the
              unwanted lines from appearing with facial expression and often
              even at rest. These two products work together in tandem to help
              maximize the desire results.
            </div>
          </FadeInSection>
          <FadeInSection>
            {" "}
            <h3 style={{ marginTop: "2rem" }}>
              How long do the results of Botox® last?
            </h3>
            <div className="text-med">
              Botox® usually lasts from 3-4 months. When the effect of Botox®
              wears off or diminishes, the muscles that contribute to these
              facial expression lines again become active, and the treatment can
              again be administered.
            </div>
          </FadeInSection>
          <FadeInSection>
            {" "}
            <h3 style={{ marginTop: "2rem" }}>
              Are there any limitations after treatment?
            </h3>
            <div className="text-med">
              Yes. Refrain from touching the area of treatment for at least 1
              hour. By pushing on the area that was treated you may cause a
              negative effect.
            </div>
          </FadeInSection>
          <FadeInSection>
            {" "}
            <h3 style={{ marginTop: "2rem" }}>
              Are there any risks or side effects?
            </h3>
            <div className="text-med">
              Rarely. If Botox® should affect an adjacent muscle, it too may
              have reduced activity. This has been reported in about one percent
              of those treated and if this does develop, the most common change
              is eyelid drooping. However, any such unwanted muscle relaxation
              would also be temporary. There are no reports of any injury to the
              eye when Botox is administered to the muscles of the forehead.
            </div>
          </FadeInSection>
          <FadeInSection>
            {" "}
            <h3 style={{ marginTop: "2rem" }}>Can Botox® cause botulism?</h3>
            <div className="text-med">
              No. Extraordinarily small amounts of Botox® are administered.
              These quantities are insufficient to cause botulism or any related
              symptoms.
            </div>
          </FadeInSection>
          <FadeInSection>
            {" "}
            <h3 style={{ marginTop: "2rem" }}>
              What other conditions can Botox be used for?
            </h3>
            <div className="text-med">
              Botox can be used to treat migraine headaches, masseter or
              temporomandibular joint (TMJ) pain or hyperhidrosis also known as
              excessive sweating.
            </div>
          </FadeInSection>
        </div>
        <div className="info-screen-side">
          <div style={{ marginBottom: "3rem" }}>
            {" "}
            <ContactForm small={true} />
          </div>
          <div style={{ marginBottom: "3rem" }}>
            <Directions />
          </div>
          <MailListForm small={true} />
        </div>
      </div>
    </div>
  );
};

export default BotoxScreen;
