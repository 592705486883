import React from 'react';

const SpecialsButton = (props) => {
    return (
      
        <button className="specials-button-full" onClick={props.onClick}>OUR SPECIALS</button>

    );
};

export default SpecialsButton;