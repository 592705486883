import styled from "styled-components";

export const Box = styled.div`
  ${({ margin }) => margin && `margin: ${margin}`};
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop}`};
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}`};
  ${({ marginLeft }) => marginLeft && `margin-left: ${marginLeft}`};
  ${({ marginRight }) => marginRight && `margin-right: ${marginRight}`};
  ${({ padding }) => padding && `padding: ${padding}`};
  ${({ paddingTop }) => paddingTop && `padding-top: ${paddingTop}`};
  ${({ paddingBottom }) => paddingBottom && `padding-bottom: ${paddingBottom}`};
  ${({ paddingLeft }) => paddingLeft && `padding-left: ${paddingLeft}`};
  ${({ paddingRight }) => paddingRight && `padding-right: ${paddingRight}`};
`;

export const Flex = styled(Box)`
  display: flex;
  ${({ gap }) => gap && `gap: ${gap}`};
  ${({ flexDirection }) => flexDirection && `flex-direction: ${flexDirection}`};
  ${({ justifyContent }) =>
    justifyContent && `justify-content: ${justifyContent}`};
  ${({ alignItems }) => alignItems && `align-items: ${alignItems}`};
  ${({ wrap }) => wrap && `flex-wrap: wrap`};
`;
