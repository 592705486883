import React, { useEffect } from "react";
import FadeIn from "../components/FadeIn";
import headshot from "../images/headshot-new.png";

const AboutUsScreen = () => {
  useEffect(() => {
    document.title = "About ShotDocMD";
  }, []);
  return (
    <div className="contain">
      <h1>About ShotDocMD</h1>
      <div className="row" style={{ marginTop: "3rem" }}>
        <div className="col-one" style={{ marginBottom: "1.5rem" }}>
          <FadeIn>
            <img src={headshot} alt="headshot" className="headshot" />
          </FadeIn>
        </div>

        <div className="col-two">
          <FadeIn>
            <p>
              Dr. Grace L. Kim is the former owner of Rejuvenation Medical
              Skincare and Laser, Inc., a full service medical spa in San Luis
              Obispo, which she operated from 2005-2014. During those nine
              years, she fully dedicated herself to the operations of the
              medical spa industry, reaching the Platinum level with Allergan
              (makers of Botox cosmetic). In 2018, she opened ShotDocMD in San
              Jose, in order to provide cutting-edge injectable services to the
              San Jose area. She is one of the most-trained and experienced
              injectors in the area and she continues to focus on the latest
              techniques and products for a superlative patient experience.
            </p>
            <p>
              Dr. Kim has worked in the medical spa business for seventeen
              years, and has built her reputation as an artist with attention to
              detail. She works with her patients to achieve a fresh, natural
              appearance. She understands the desire to look and feel great,
              regardless of age. She brings compassion, skill and experience to
              her practice.
            </p>
            <p>
              Dr. Kim graduated from Smith College cum laude and went back to
              Canada, her native country, to complete her M.D. at McGill
              University Faculty of Medicine. She is a board-certified
              anesthesiologist and completed two residencies in both pediatrics
              and anesthesiology. She has trained at several preeminent medical
              facilities including Cedars-Sinai Medical Center, UCLA and Johns
              Hopkins Hospital.
            </p>
            <p>
              Besides working as an anesthesiologist in San Luis Obispo and San
              Jose, she has donated her time to medical mission work in Africa.
              Her greatest achievement, however, has been as a mother of three
              children. In her spare time she enjoys yoga, hiit classes, hiking,
              stand-up paddle boarding, adventure travel and taking her dogs to
              the redwoods.
            </p>
          </FadeIn>
        </div>
      </div>
    </div>
  );
};

export default AboutUsScreen;
