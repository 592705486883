import React, { useEffect } from "react";
import Map from "../components/Map";
import googleMaps from "../assets/googleMaps.svg";
import appleMaps from "../assets/appleMaps.svg";
import FadeIn from "../components/FadeIn";

const GetDirectionsScreen = () => {
  useEffect(() => {
    document.title = "Directions - Los Gatos - ShotDocMD";
  }, []);

  return (
    <div>
      <div className="contain">
        <h1>Directions - Los Gatos</h1>

        <FadeIn>
          <div className="middle-align">
            <h3>
              ShotDocMD is located at 761 University Ave., Suite B, Los Gatos,
              CA 95032
            </h3>

            <a
              href="https://www.google.com/maps/place/761+University+Ave,+Los+Gatos,+CA+95032/@37.2390858,-121.9756623,18.89z/data=!4m6!3m5!1s0x808e35948715bc5b:0x5df86cfb2d896bfb!8m2!3d37.2391854!4d-121.9752156!16s%2Fg%2F11gdqjffw6?entry=ttu"
              // className="directions-button"
              className="maps-icons"
            >
              <img src={googleMaps} alt="Google Maps" width="90rem"></img>
            </a>
            <a
              href="http://maps.apple.com/?address=761,University+Ave,Los+Gatos,California"
              className="maps-icons"
            >
              <img
                src={appleMaps}
                alt="Apple Maps"
                width="82.5rem"
                style={{ marginTop: "1rem" }}
              ></img>
            </a>
          </div>
        </FadeIn>
      </div>
      <FadeIn>
        <Map height="83vh" width="100%" isLosGatos />
      </FadeIn>
    </div>
  );
};

export default GetDirectionsScreen;
