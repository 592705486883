import React, { useEffect } from "react";
import image from "../images/injectables.jpg";
import FadeInSection from "../components/FadeInSection";
import ContactForm from "../components/ContactForm";
import MailListForm from "../components/MailListForm";
import Directions from "../components/Directions";
import FadeIn from "../components/FadeIn";

const InjectablesScreen = () => {
  useEffect(() => {
    document.title = "Injectables - ShotDocMD";
  }, []);

  return (
    <div className="contain">
      <div className="info-screen-container">
        <div className="info-screen-main">
          <FadeIn>
            <h1>Injectables</h1>

            <div className="subheading">
              Are you looking for injections in Los Gatos, California?
            </div>
          </FadeIn>

          <br></br>
          <FadeInSection>
            {" "}
            <div style={{ margin: "1rem 0 3rem 0" }}>
              <img src={image} alt="injectables" className="image" />
            </div>
          </FadeInSection>
          <FadeInSection>
            {" "}
            <div className="text-med">
              What are injectables and what are they used for? Dr. Kim is asked
              this question often during consultations as there is much
              confusion out there about injectables.
            </div>
          </FadeInSection>
          <br></br>
          <FadeInSection>
            {" "}
            <div className="text-med">
              First of all, injectables, as they sound, are tiny pokes under the
              skin. To make things simple, there are two types of injections:
            </div>
          </FadeInSection>

          <FadeInSection>
            {" "}
            <div className="text-med">
              <ol>
                <li>
                  Injections to <strong>relax the muscles</strong> under the
                  skin to smooth out wrinkles such as Botox.
                </li>
                <li>
                  Injections to <strong>add volume</strong> to the skin in the
                  cheeks, lips, tear trough, temple, chin and acne scars such as
                  Juvederm, Restylane, Radiesse, Sculptra, Versa, and your own
                  blood cells such as PRP/PRF or platelet rich cells.
                </li>
              </ol>
            </div>
          </FadeInSection>
          <FadeInSection>
            {" "}
            <div className="text-med">
              Injectables have come a long way from 2005, when Dr. Kim first
              started the business. Back then, <strong>Botox®</strong> was used
              primarily for the frown lines, and forehead. Today Botox® Cosmetic
              is used to relax the muscles in the frown lines, forehead, crows
              feet, upper lip (lip flip), platysmal bands, neck (Nefertiti neck
              lift) as well as the masseters for jawline slimming and TMJ
              relief. Botox® is also used to treat the nagging and often
              embarrassing problem of hyperhidrosis or excessive sweating in the
              hands and armpits.
            </div>
          </FadeInSection>
          <br></br>

          <FadeInSection>
            {" "}
            <div className="text-med">
              <strong>Fillers</strong> are used to contour the face, adding lift
              and fullness to an otherwise volume-depleted or two-dimensional
              face. As we age, beginning in the mid-20’s, we begin to lose the
              deep fat that gives structure and youthful volume to our face. The
              first place we notice facial changes is under the eyes, giving us
              a hollow or tired appearance. As the decades progress, there is
              loss of facial muscle tone and even bone loss. Fillers, placed
              strategically with a keen eye and understanding of the aging
              anatomy, can be remarkably effective in restoring youth naturally
              and without surgery.
            </div>
          </FadeInSection>
          <br></br>

          <FadeInSection>
            {" "}
            <div className="text-med">
              Surgical correction may be inevitable, especially when there is
              excessive skin and laxity. But Botox and fillers are still a
              necessary maintenance program to combat wrinkle reduction and
              volume loss, post face and/or neck lift.
            </div>
          </FadeInSection>
          <br></br>

          <FadeInSection>
            {" "}
            <div className="text-med">
              Dr. Kim has been in the aesthetic medspa business for over 16
              years, and has truly grown with the advancements in facial
              injectables. She has dedicated years perfecting her techniques and
              has trained with the best in the industry. At this time, her
              aesthetic practice is wholly focused on injectables. She uses the
              latest in products and techniques to achieve a fresh and natural
              look. She treats women, men, transgender, young, more mature, and
              patients of different ethnic backgrounds.
            </div>
          </FadeInSection>
        </div>
        <div className="info-screen-side">
          <div style={{ marginBottom: "3rem" }}>
            {" "}
            <ContactForm small={true} />
          </div>
          <div style={{ marginBottom: "3rem" }}>
            <Directions />
          </div>
          <MailListForm small={true} />
        </div>
      </div>
    </div>
  );
};

export default InjectablesScreen;
