import React from "react";
import first from "../images/carousel1.jpg";
import second from "../images/carousel2.jpg";
import fourth from "../images/carousel4.jpg";
import eight from "../images/carousel8.jpg";
import eightMobile from "../images/carousel8Mobile.jpg";
import nine from "../images/carousel9.jpg";
import nineMobile from "../images/carousel9Mobile.jpg";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const CarouselScreen = () => {
  return (
    <Carousel
      showStatus={false}
      emulateTouch
      showArrows={false}
      autoPlay
      interval={3000}
      showThumbs={false}
      infiniteLoop
    >
      <div>
        <picture>
          <source media="(max-width: 799px)" srcset={first} />
          <source media="(min-width: 800px)" srcset={first} />
          <img
            style={{ height: "64vh", minHeight: "700px", objectFit: "cover" }}
            className="d-block w-100"
            src={first}
            alt="First slide"
          />{" "}
        </picture>
      </div>
      <div>
        <picture>
          <source media="(max-width: 799px)" srcset={eightMobile} />
          <source media="(min-width: 800px)" srcset={eight} />
          <img
            className="d-block w-100"
            src={eight}
            alt="Second slide"
            style={{ height: "64vh", minHeight: "700px", objectFit: "cover" }}
          />
        </picture>
      </div>
      <div>
        <img
          className="d-block w-100"
          src={second}
          alt="third slide"
          style={{ height: "64vh", minHeight: "700px", objectFit: "cover" }}
        />
      </div>
      <div>
        <picture>
          <source media="(max-width: 799px)" srcset={nineMobile} />
          <source media="(min-width: 800px)" srcset={nine} />
          <img
            style={{ height: "64vh", minHeight: "700px", objectFit: "cover" }}
            className="d-block w-100"
            src={nine}
            alt="fourth slide"
          />{" "}
        </picture>
      </div>
      <div>
        <img
          className="d-block w-100"
          src={fourth}
          alt="fifth slide"
          style={{ height: "64vh", minHeight: "700px", objectFit: "cover" }}
        />
      </div>
    </Carousel>
  );
};

export default CarouselScreen;
