import React from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const losGatos = {
  address: "761 University Ave., Suite B, Los Gatos, CA 95032",
  center: {
    lat: 37.2392,
    lng: -121.97523,
  },
};

const slo = {
  address: "1250 Peach St, San Luis Obispo, CA 93401",
  center: {
    lat: 35.28658,
    lng: -120.66015,
  },
};

function Map(props) {
  const { isLosGatos } = props;
  const location = isLosGatos ? losGatos : slo;

  const defaultOptions = {
    mapTypeControl: false,
    streetViewControl: false,
    fullscreenControl: false,
  };

  return (
    <div style={{ height: `${props.height}`, width: `${props.width}` }}>
      <LoadScript googleMapsApiKey="AIzaSyCdLIlKp6iFyoP3H0QIX5R2B5CkVISWZa4">
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={location.center}
          zoom={18}
          options={defaultOptions}
        >
          {/* Child components, such as markers, info windows, etc. */}
          <Marker position={location.center} address={location.address} />
        </GoogleMap>
      </LoadScript>
    </div>
  );
}

export default Map;
