import React from "react";
import { Link } from "react-router-dom";
import logo from "../images/logo-alt.png";
// import isOpen from "./isOpen";
import { BOOKING_URL } from "../utils/constants";

const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="column">
          <div className="center">
            <div className="logo-footer">
              <img src={logo} alt="logo" className="img-logo" />
            </div>
          </div>
          {/* <div style={{ textAlign: "center" }}>
            <Link
              to="/contact/hours"
              className={
                isOpen
                  ? "center open-closed-text-active"
                  : "center open-closed-text"
              }
            >
              {" "}
              {isOpen ? "We are currently open" : "We are currently closed"}
            </Link>
          </div> */}
          <div className="center" style={{ textAlign: "center" }}>
            <Link to="/contact/directions" className="footer-address-link">
              761 University Ave, Suite B<br></br>
              Los Gatos, CA 95032
            </Link>
          </div>

          <div className="row-fixed center" style={{ alignItems: "center" }}>
            <a href="https://www.facebook.com/shotdocmd">
              <i className="fab fa-facebook-f footer-links"></i>
            </a>
            <a href="https://www.instagram.com/shotdocmd/?hl=en">
              <i className="fab fa-instagram footer-links"></i>
            </a>
            <a href="https://www.yelp.com/biz/shotdocmd-san-jose-2">
              <i className="fab fa-yelp footer-links"></i>
            </a>
            <a href="https://www.google.com/search?q=shotdocmd&oq=shotdocmd&aqs=chrome.0.69i59l2j69i60l4.3994j0j1&sourceid=chrome&ie=UTF-8#lrd=0x808e355d1e22bdb1:0xeea0301d1b46ef11,1,,,">
              <i className="fab fa-google footer-links"></i>
            </a>
          </div>

          <div
            className="row-fixed footer-mobile-hide"
            style={{ justifyContent: "space-evenly", marginBottom: "2rem" }}
          >
            <div
              className="column quarter"
              style={{ justifyContent: "flex-start" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                }}
              >
                <Link to="/services" className="footer-subheading center">
                  Services
                </Link>
                <div className="row-fixed center">
                  <div className="column">
                    <Link to="/services/botox" className="footer-link">
                      Botox®
                    </Link>
                    <Link to="/services/injectables" className="footer-link">
                      Injectables
                    </Link>
                    <Link to="/services/microneedling" className="footer-link">
                      Microneedling
                    </Link>
                    <Link to="/services/melasma" className="footer-link">
                      Melasma
                    </Link>
                  </div>
                  <div className="column" style={{ marginLeft: "1.2rem" }}>
                    <Link to="/services/prf-facelift" className="footer-link">
                      PRF Facelift
                    </Link>
                    <Link to="/services/kybella" className="footer-link">
                      KYBELLA®
                    </Link>
                    <Link to="/services/vi-peel" className="footer-link">
                      ViPeel
                    </Link>
                    <Link to="/services/hyperhidrosis" className="footer-link">
                      Hyperhidrosis
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="column quarter"
              style={{ justifyContent: "flex-start" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                }}
              >
                <Link to="/resources" className="footer-subheading">
                  Resources
                </Link>

                {/* <Link to="/resources/location" className="footer-link">
                  Location
                </Link> */}
                <Link to="/resources/forms" className="footer-link">
                  Patient Forms
                </Link>
                <Link to="/resources/pricing" className="footer-link">
                  Pricing
                </Link>
                <Link to="/resources/reviews" className="footer-link">
                  Reviews
                </Link>
                {/* <Link to="/resources/virtual-tour" className="footer-link">
                  Virtual Tour
                </Link> */}
              </div>
            </div>
            <div
              className="column quarter"
              style={{ justifyContent: "flex-start" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                }}
              >
                {" "}
                <Link to="contact" className="footer-subheading">
                  Contact Us
                </Link>
                <Link to="/contact/directions" className="footer-link">
                  Get Directions
                </Link>
                <Link to="/contact/about" className="footer-link">
                  About Us
                </Link>
                <Link to="/contact/hours" className="footer-link">
                  Business Hours
                </Link>
              </div>
            </div>
            <div
              className="column quarter"
              style={{ justifyContent: "flex-start" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                }}
              >
                <div className="footer-subheading-div">Book an Appointment</div>

                <a href={BOOKING_URL} className="footer-link">
                  Book Online
                </a>
                <a className="footer-link" href="tel:8052351400">
                  Call Us
                </a>
                <a className="footer-link" href="tel:8052351400">
                  Text Us
                </a>
              </div>
            </div>
          </div>
          <div className="mobile-show center">
            <a href={BOOKING_URL} className="footer-link mobile-show-content">
              Book Online
            </a>
            <a
              className="footer-link mobile-show-content"
              href="tel:8052351400"
            >
              Call Us
            </a>
            <a
              className="footer-link mobile-show-content"
              href="tel:8052351400"
            >
              Text Us
            </a>
          </div>
          <div className="center copyright">
            <i className="far fa-copyright"></i> ShotDocMD{" "}
            {new Date().getFullYear()} | Developed by{" "}
            <a href="https://www.lucasabroms.com" className="copyright-link">
              Lucas Abroms
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
