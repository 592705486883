import React, { useEffect } from 'react';
import FadeIn from '../components/FadeIn';

const PoliciesScreen = () => {

    useEffect(() => {
      document.title = "Policies and Procedures - ShotDocMD";
    }, []);

    return (
        <div className="contain">
          <h1>Policies and Procedures</h1>
           <FadeIn>
          <div className="subheading container" style={{textAlign: "left"}}>
                      <br />

              Please arrive at least 5 minutes prior to your appointment time to fill out consent and patient forms.
            <br />
            <br />
            If you need to cancel your appointment, we respectfully request a <span className="bold">minimum of 24 hours</span> notice.  Any cancellation or reschedule made less than 24 hours will result in a cancellation fee.  For a 30 minute session, you will be charged $50, and for 60 minutes, $100.  
            <br />
            <br />
            Please note that all treatment sales are final at time of service.  All packages must be redeemed within 12 months of date of purchase.
          </div>
        </FadeIn>
        </div>
    );
};

export default PoliciesScreen;