import React, { useEffect } from "react";
import image from "../images/carousel7.jpg";
import FadeInSection from "../components/FadeInSection";
import ContactForm from "../components/ContactForm";
import MailListForm from "../components/MailListForm";
import Directions from "../components/Directions";
import FadeIn from "../components/FadeIn";

const KybellaScreen = () => {
  useEffect(() => {
    document.title = "KYBELLA® - ShotDocMD";
  }, []);

  return (
    <div className="contain">
      <div className="info-screen-container">
        <div className="info-screen-main">
          <FadeIn>
            <h1>KYBELLA®</h1>

            <div className="subheading">
              Are you looking for a KYBELLA® procedure near Los Gatos, CA?
            </div>
          </FadeIn>

          <br></br>
          <FadeInSection>
            {" "}
            <div style={{ margin: "1rem 0 3rem 0" }}>
              <img src={image} alt="botox" className="image" />
            </div>
          </FadeInSection>
          <FadeInSection>
            {" "}
            <h2 style={{ marginTop: "3rem", textAlign: "center" }}>
              KYBELLA® is the non-surgical answer for the “double chin"!
            </h2>
          </FadeInSection>

          <FadeInSection>
            {" "}
            <div className="text-med">
              It is the first and only FDA-approved injectable to improve the
              appearance of fat beneath the chin by physically destroying
              adipose cells.
            </div>
            <br></br>
            <div className="text-med">
              KYBELLA® is a man-made form of deoxycholic acid, a naturally
              occurring material that helps the body absorb fat. In survey, 67%
              of people are bothered by the appearance of fat under the chin,
              and now with KYBELLA® you can destroy the fat cells contributing
              to this pesky problem.
            </div>
          </FadeInSection>
          <br></br>

          <FadeInSection>
            <div className="text-med">
              Causes of the “double chin” include weight gain, genetics and
              aging…
            </div>
          </FadeInSection>
          <FadeInSection>
            <div className="text-med">
              If you’ve tried every diet and exercise plan imaginable but still
              struggle with unwanted neck and chin fat, you aren’t alone. Many
              people who eat healthy meals and have no problem toning their
              arms, legs and abdominals still find it difficult to eradicate the
              fat around their submental (under the chin) area. This low
              hanging, fleshy region is often referred to as a “double chin.”
            </div>
          </FadeInSection>
          <br></br>
          <FadeInSection>
            <div className="text-med">
              With KYBELLA®, you can eliminate the need for liposuction or other
              invasive fat-elimination techniques. Through a series of
              injections, KYBELLA® mimics the body’s natural fat-absorption
              process and leaves you with a sleek and slim chin area.
            </div>
          </FadeInSection>
          <FadeInSection>
            {" "}
            <h3 style={{ marginTop: "2rem" }}>
              KYBELLA® is the non-surgical answer for the “double chin"!
            </h3>
            <div className="text-med">
              <ul>
                <li>KYBELLA® is FDA-approved</li>
                <li>No anesthesia or surgery required</li>
                <li>Fat cells are destroyed for good</li>
                <li>Fast and efficient treatments</li>
              </ul>
            </div>
          </FadeInSection>
          <FadeInSection>
            <div className="text-med">
              When KYBELLA® is injected into the subcutaneous fat of the
              submental region, the membranes of the targeted fat cells are
              destroyed. Once destroyed, the cells are no longer able to store
              fat. The destruction of these cells is then followed by the body’s
              natural elimination and healing process. This process is slow but
              steady, and the fat cells are typically removed from the area
              within 4 to 6 weeks after receiving injections.
            </div>
          </FadeInSection>
          <FadeInSection>
            {" "}
            <h3 style={{ marginTop: "2rem" }}>
              HOW MANY KYBELLA® TREATMENT SESSIONS WILL I NEED?
            </h3>
            <div className="text-med">
              Since everyone’s chin profile is different, the number of
              treatments varies from patient to patient. Dr. Grace Kim will help
              determine how many treatment sessions you need based on the amount
              and distribution of your submental fat and your personal treatment
              goals. Each KYBELLA® treatment session is given at least 1 month
              apart, and you should not receive more than 6 treatments. In
              clinical studies, 59% of patients received 6 treatments.
            </div>
          </FadeInSection>
        </div>
        <div className="info-screen-side">
          <div style={{ marginBottom: "3rem" }}>
            {" "}
            <ContactForm small={true} />
          </div>
          <div style={{ marginBottom: "3rem" }}>
            <Directions />
          </div>
          <MailListForm small={true} />
        </div>
      </div>
    </div>
  );
};

export default KybellaScreen;
