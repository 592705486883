import React from 'react';
import { Link } from 'react-router-dom';
import image from '../images/aging.jpg';

const AgingWithGrace = () => {
    return (
      <div className="aging-face-container">
        <div className="contain-aging">
          <div className="row" style={{ marginTop: "1rem" }}>
            <div className="col-40" style={{ marginBottom: "1rem" }}>
              <div className="aging-image-container">
                <img src={image} alt="aging" className="aging-image" />
              </div>
            </div>

            <div className="col-60">
              <h2 className="subheading-aging">
                The Aging Face and How To Age with Grace
              </h2>
              <p className="p-text">
                Aging is a natural process and along with wisdom, experience,
                and accomplishments that come with getting older, our physical
                appearance changes as well. The aging process is the inevitable
                and predictable result of living longer and we can embrace the
                journey with a few tips and treatments to age more gracefully.
              </p>
              <p className="p-text">
                There are two distinct aging systems, intrinsic and extrinsic,
                that affect our aging face. Intrinsic, or natural aging begins
                in our mid-20’s and continues steadily throughout life. Genetics
                plays a key role and happily or unhappily we can blame our
                parents for our older appearance. Extrinsic aging, on the other
                hand, are those factors that can accelerate our aging faces.
                These include sun exposure, pollution, stress, smoking,
                excessive alcohol and lack of skin care.{" "}
                <Link style={{ color: "#F16681" }} to="/aging-with-grace">
                  Read More
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
};

export default AgingWithGrace;