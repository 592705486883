import React, { useEffect } from "react";
import image from "../images/carousel6.jpg";
import FadeInSection from "../components/FadeInSection";
import ContactForm from "../components/ContactForm";
import MailListForm from "../components/MailListForm";
import Directions from "../components/Directions";
import FadeIn from "../components/FadeIn";

const ViPeelScreen = () => {
  useEffect(() => {
    document.title = "ViPeel - ShotDocMD";
  }, []);

  return (
    <div className="contain">
      <div className="info-screen-container">
        <div className="info-screen-main">
          <FadeIn>
            {" "}
            <h1>ViPeel - Vitality Institute Chemical Peels</h1>
            <div className="subheading">
              Are you looking for a chemical peel procedure near Los Gatos, CA?
            </div>
            <br></br>
          </FadeIn>

          <FadeInSection>
            {" "}
            <div style={{ margin: "1rem 0 3rem 0" }}>
              <img src={image} alt="botox" className="image" />
            </div>
          </FadeInSection>
          <FadeInSection>
            <div className="text-med">
              The VI Peel is a medical grade chemical peel that is
              scientifically formulated to treat hyperpigmentation, aging skin,
              acne and rosacea. The VI Peel is a proprietary blend of chemical
              ingredients that work on all skin types. The peel delivers
              dramatic results with minimal downtime. The active ingredients in
              the VI Peel are synergistically blended and suitable for all skin
              types. The peel improves the tone, texture and clarity of the
              skin. Enjoy radiant, younger looking skin in about a week with the
              VI Peel.
            </div>
          </FadeInSection>

          <FadeInSection>
            {" "}
            <h3 style={{ marginTop: "2rem" }}>
              There are four VI Peels, all containing world class ingredients
              which include:{" "}
            </h3>
            <div className="text-med">
              <ul>
                <li>Trichloroacetic acid (TCA)</li>
                <li>Salicylic acid</li>
                <li>Retinoic acid</li>
                <li>Phenol</li>
                <li>Hydroquinone</li>
                <li>Kojic Acid</li>
                <li>Vitamin C (L-ascorbic acid)</li>
                <li>Benzoyl peroxide (BPO)</li>
                <li>Hydrocortisone</li>
                <li>Proprietary mineral blend</li>
              </ul>
            </div>
          </FadeInSection>

          <FadeInSection>
            <div className="text-med">
              Each VI Peel incorporates a two part system to ensure patient
              satisfaction with visible exfoliation. Following the peel, a
              retinoic acid towelette is applied to the skin at three specific
              times to boost the results of the peel. The skin will feel tight
              initially, then the sloughing begins on day 3. Patients are done
              with the peeling at the one week mark.
            </div>
          </FadeInSection>

          <FadeInSection>
            {" "}
            <h3 style={{ marginTop: "2rem" }}>
              VI Peel includes a post-peel system that includes
            </h3>
            <div className="text-med">
              <ul>
                <li>VI Derm SPF 50 plus sun protection</li>
                <li>VI Derm post-peel protectant</li>
                <li>3 VI Peel post peel towelettes</li>
                <li>VI Skin cleanser</li>
              </ul>
            </div>
          </FadeInSection>

          <FadeInSection>
            <div className="text-med">
              To maintain the health of the skin, it is recommended that a
              series of the VI Peel be administered approximately 3 times a
              year, more often for more severe skin damage, and photoaging.
            </div>
          </FadeInSection>
        </div>
        <div className="info-screen-side">
          <div style={{ marginBottom: "3rem" }}>
            {" "}
            <ContactForm small={true} />
          </div>
          <div style={{ marginBottom: "3rem" }}>
            <Directions />
          </div>
          <MailListForm small={true} />
        </div>
      </div>
    </div>
  );
};

export default ViPeelScreen;
