import React from 'react';

const SignsOfAging = () => {
    return (
      <div className="aging-face-container" style={{backgroundColor: '#fff'}}>
        
        <div className="center">
          <div className="contain-small column med-text">
            
            <h2 className="subheading center">Signs of Aging</h2>
            <ol>
              <li>Skin color becomes more gray because of less blood flow.</li>
              <li>
                Facial shape becomes collapsed and droopy as muscle and collagen
                decrease and some areas of the face loses fat volume, clumps and
                shifts downward. Other areas of the face gain fat, particularly
                the lower half, so we tend to get baggy around the chin and
                jowly in the neck.
              </li>
              <li>
                Wrinkles appear, which is the result of facial muscles making
                expression and continually creasing the overlying skin.
              </li>
              <li>
                Skin texture becomes less smooth as dead skin cells do not shed
                as quickly and the production of new cells slow down.
              </li>
            </ol>
            <h2 className="subheading center">Choosing How to Age Gracefully</h2>
            <ol>
              <li>
                The basics: Hydrate, avoid the sun and be fanatical about using
                sunscreen. Get enough rest, exercise and sleep and quit smoking.
              </li>
              <li>
                Skin Care: Wash your face every night before sleep and, during
                the day, remove all the excess residue from working out or
                pollution. Exfoliate your skin with a product that is
                nonirritating and removes debris and dead skin cells. Moisturize
                , ideally with a moisturizer that has sunscreen, particularly
                around the eyes where the skin is thinner. Consider topical
                Vitamin A, retinol or a stronger prescription tretinoin which
                increases collagen synthesis and helps with discoloration by
                peeling away the top dead layer of the skin. Consider anti-aging
                creams with ingredients such as Vitamin C, hydroxy acids and
                co-enzyme Q.
              </li>
              <li>
                Botulinum toxin injections: Botox, Xeomin and Dysport are all
                forms of botulinum toxin. These injections are used to partially
                and temporarily immobilize the muscles that form expression
                lines of the forehead, between the brows, crows feet, pebbly
                chin and neck bands.
              </li>
              <li>
                Dermal fillers: After Botox, dermal fillers are the most common
                cosmetic procedure performed in the United States. Fillers are
                used to treat lines created by lost collagen and fat. Many
                different materials are used as dermal filler, the most popular
                being hyaluronic acid such as Juvederm and Restylane. Like
                Botox, the effect of the dermal filler wears off after several
                months.
              </li>
              <li>
                Microneedling, chemical peels, PRP injections, and lasers: These
                are treatments that are designed to boost collagen production,
                improve skin texture and soften wrinkles. Maintaining good skin
                and reversing sun damage requires multiple treatments.
              </li>
            </ol>
            <p>
              So as you can see, you can both reverse and maintain your face and
              skin even as you age in years. Remember, your skin did not age
              overnight. Some of these treatments are almost immediate such as
              Botox and fillers, while others require healing and a maintenance
              program. Call and make your appointment to see what treatments are
              right for you!
            </p>
          </div>
        </div>
      </div>
    );
};

export default SignsOfAging;